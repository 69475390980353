import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  BrowseState,
  FetchInputData,
  FilterResultData,
  FilterType,
  FilterUpdateData,
  getDurationMs,
  InitData,
  SortType,
  WorkoutSelectedData,
  WorkoutVideo
} from "./types"
import { sortWorkoutsByDifficulty, sortWorkoutsBySchedule, sortWorkoutsByTime } from "./browseSaga"
import moment from "moment"

function initActiveFilters() {
  let filter: any = {}
  Object.keys(FilterType).forEach(k => {// @ts-ignore
    filter[FilterType[k as any]] = {}
  })
  return filter
}

const initialState: BrowseState = {
  selectionType: null,
  hydrated: false,
  loading: false,
  allWorkouts: [],
  scheduledClasses: [],
  activeFilters: initActiveFilters(),
  transientFilters: initActiveFilters(),
  activeSort: SortType.difficulty,
  filteredWorkouts: [],
  filterStatus: "init",
  filterBreadcrumbs: null,
  showScheduleActive: false,
  normalWorkoutsTitle: "On-Demand classes"
}

export const browseSlice = createSlice({
  name: "browse",
  initialState: initialState,
  reducers: {
    init: state => state,
    hydrateBrowseState: (state, action: PayloadAction<InitData>) => {
      if (!state.hydrated) {
        const normalWorkouts = action.payload.workouts.map((wn) => Object.assign(wn))
        sortWorkoutsByDifficulty(normalWorkouts)
        const endTime = moment().add(2, "days").endOf("days").valueOf()

        const featuredWorkouts = action.payload.schedule
          .filter((s) => s.classTimestamp <= endTime)
          .reduce((result, element) => {
            const sw = normalWorkouts.find((w) => w.id == element.wid)
            if (sw != null && (element.classTimestamp + getDurationMs(sw.durationCategory) > Date.now())) {
              let w: WorkoutVideo = Object.assign({}, sw)
              w.scheduleInfo = {
                startTimeInMs: element.classTimestamp,
                id: element.id,
                isLive: false,
                ctaState: "invite"
              }
              result.push(w)
            }
            return result
          }, Array<WorkoutVideo>())

        sortWorkoutsBySchedule(featuredWorkouts)
        state.allWorkouts = normalWorkouts
        state.scheduledClasses = featuredWorkouts
        state.filteredWorkouts = normalWorkouts

        //TODO sort workouts
        state.error = null
        state.loading = false
        state.hydrated = true
      }
    },
    scheduleClassesUpdated: (state, { payload }: PayloadAction<WorkoutVideo[]>) => {
      state.scheduledClasses = payload
    },
    workoutSelected: (state, { payload }: PayloadAction<WorkoutSelectedData>) => {
      state.selectedWorkout = payload.workout
      state.selectionType = payload.type
    },
    updateSelectedWorkout: (state, { payload }: PayloadAction<WorkoutVideo>) => {
      state.selectedWorkout = payload
    },
    clearSelectedWorkout: (state) => {
      state.selectedWorkout = null
      state.selectionType = null
    },

    filterWorkoutsSuccess: (state, { payload }: PayloadAction<FilterResultData>) => {
      state.filteredWorkouts = payload.workouts
      state.normalWorkoutsTitle = payload.title
      state.filterBreadcrumbs = payload.filterBreadcrumbs
      state.filterStatus = "complete"
    },

    filterValueChanged: (state, { payload }: PayloadAction<FilterUpdateData>) => {
      updateFilterObject(state.activeFilters, payload)
    },

    transientFilterValueChanged: (state, { payload }: PayloadAction<FilterUpdateData>) => {
      updateFilterObject(state.transientFilters, payload)
    },

    initTransientFilters: (state) => {
      state.transientFilters = Object.assign(state.activeFilters)
    },
    applyTransientFilters: (state) => {
      state.activeFilters = Object.assign(state.transientFilters)
    },
    clearFilters: (state) => {
      state.activeFilters = initActiveFilters()
      state.transientFilters = Object.assign(state.activeFilters)
    },
    previewWorkoutById: (state, action: PayloadAction<FetchInputData>) => state,

    applyingSortAndFilter: (state) => {
      state.filterStatus = "filtering"
    },

    sortWorkouts: (state, { payload }: PayloadAction<SortType>) => {
      state.activeSort = payload
    },
    sortClicked: (state) => state,
    toggleShowSchedule: (state) => {
      state.showScheduleActive = !state.showScheduleActive
    },
    clean: (state) => state
  }

})

function updateFilterObject(filters: any, filterUpdateData: FilterUpdateData) {
  if (filterUpdateData.active) {
    filters[filterUpdateData.filterType.toString()][filterUpdateData.id] = filterUpdateData.active
    filters[filterUpdateData.filterType.toString()][`${filterUpdateData.id}_display`] = filterUpdateData.display
  } else {
    delete filters[filterUpdateData.filterType.toString()][filterUpdateData.id]
    delete filters[filterUpdateData.filterType.toString()][`${filterUpdateData.id}_display`]
  }
}

function getFilteredWorkoutsToShow(allFeaturedWorkouts: WorkoutVideo[] | undefined, showAllWorkouts: boolean): WorkoutVideo[] {
  if (showAllWorkouts)
    return allFeaturedWorkouts ?? []
  return Array.from(allFeaturedWorkouts ?? []).splice(0, 3)
}


export const {
  init,
  hydrateBrowseState,
  workoutSelected,
  updateSelectedWorkout,
  scheduleClassesUpdated,
  clearSelectedWorkout,
  filterValueChanged,
  filterWorkoutsSuccess,
  initTransientFilters,
  transientFilterValueChanged,
  applyTransientFilters,
  clearFilters,
  previewWorkoutById,
  applyingSortAndFilter,
  sortWorkouts,
  sortClicked,
  toggleShowSchedule,
  clean
} = browseSlice.actions

export default browseSlice.reducer
