import { ErrorInfo } from "../../redux/types"


export enum ScheduleDateFormats {
  longDate = "ddd Do MMM @ h:mma",
  displayTime = "h:mma",
  displayDay = "ddd, Do MMM",
  displayDayLong = "dddd, Do MMM",
  countdownMinSec = "m:ss",
  countdownSec = "s",
  countdownLongMinSec = "m[min] s[s]",
}

export interface BrowseState {
  hydrated: boolean
  allWorkouts: WorkoutVideo[]
  scheduledClasses: WorkoutVideo[]
  filteredWorkouts: WorkoutVideo[]
  selectedWorkout?: WorkoutVideo | null
  selectionType: WorkoutSelectedTypes
  activeFilters: any
  transientFilters: any
  activeSort: SortType
  loading: boolean
  showScheduleActive: boolean
  normalWorkoutsTitle: string
  filterBreadcrumbs: string | null
  filterStatus: "filtering" | "complete" | "init"
  error?: ErrorInfo | null
}


export function isWellnessClass(categories: string[]): boolean {
  return categories.indexOf(ClassCategory.yoga) >= 0 ||
    categories.indexOf(ClassCategory.stretch) >= 0 ||
    categories.indexOf(ClassCategory.meditation) >= 0
}

export interface WorkoutVideo {
  id: string
  creationTime: number
  scheduleInfo?: ScheduleInfo | null
  title: string
  scheduleTitle: string
  subtitle: string
  categories: string[]
  mainDifficulty: string
  difficulties: string[]
  durationCategory: string
  equipments: string[]
  thumbnailUrl: string
  localImage?: any
  gifUrl?: string | null
  instructors: string[]
  description: string
  pageTitle: string
  rating?: number | null
  playbackProgress?: number | null
  significantViews: number
}

export interface ScheduleInfo {
  id: string
  startTimeInMs: number
  isLive: boolean
  ctaState: "invite" | "join"
  countdown?: string
}

export interface ClassTimeslot {
  id: string
  wid: string
  classTimestamp: number
}

export interface InitData {
  workouts: WorkoutVideo[]
  schedule: ClassTimeslot[]
}

export interface FetchInputData {
  wid?: string | null
  tsId?: string | null
}

export interface FilterUpdateData {
  filterType: FilterType
  id: string
  display: string
  active: boolean
}

export interface FilterResultData {
  workouts: WorkoutVideo[]
  title: string
  filterBreadcrumbs: string | null
}

export type WorkoutSelectedTypes = "on-demand" | "friends-invite" | "schedule-detail" | null

export interface WorkoutSelectedData {
  workout: WorkoutVideo
  type: WorkoutSelectedTypes
  fromScheduleList?: boolean | null
}

export enum Difficulty {
  beginner = "beginner",
  intermediate = "intermediate",
  advanced = "advanced",
}

export enum FilterType {
  category = "category",
  difficulty = "difficulty",
  duration = "duration",
  equipment = "equipment",
  instructor = "instructor"
}

export enum SortType {
  difficulty = "Difficulty",
  new = "Most Recent",
  old = "Least Recent",
  category = "Class Category",
  popular = "Most popular",
  trending = "Trending (coming soon)"
}

export enum EquipmentTypeOptions {
  yes = "yes",
  no = "no",
  heavy_dumbbells = "heavy_dumbbells"
}

export enum ClassCategory {
  yoga = "yoga",
  stretch = "stretch",
  meditation = "meditation",
  strength = "strength",
  cardio = "cardio",
  core = "core"
}

export enum Duration {
  m5 = "05m",
  m10 = "10m",
  m15 = "15m",
  m20 = "20m",
  m30 = "30m",
  m45 = "45m",
}

export function getDurationDisplay(duration: Duration): string {
  switch (duration) {
    case Duration.m5:
      return "5min"
    case Duration.m10:
      return "10min"
    case Duration.m15:
      return "15min"
    case Duration.m20:
      return "20min"
    case Duration.m30:
      return "30min"
    case Duration.m45:
      return "45min"
  }
}

export function getDurationMinutes(duration: Duration): number {
  switch (duration) {
    case Duration.m5:
      return 5
    case Duration.m10:
      return 10
    case Duration.m15:
      return 15
    case Duration.m20:
      return 20
    case Duration.m30:
      return 30
    case Duration.m45:
      return 45
  }
}


export function getDurationMs(duration: Duration): number {
  return getDurationMinutes(duration) * 60 * 1000
}

export function getDifficultyIndex(difficulty: Difficulty) {
  switch (difficulty) {
    case Difficulty.intermediate:
      return 1
    case Difficulty.advanced:
      return 2
    default:
      return 0
  }

}

export function getDifficultyDisplay(difficulty: Difficulty) {
  switch (difficulty) {
    case Difficulty.beginner:
      return "Beginner"
    case Difficulty.intermediate:
      return "Intermediate"
    case Difficulty.advanced:
      return "Advanced"
    default:
      return "Beginner"
  }
}

export function getEquipmentDisplay(equipments: string[], noEquipmentString?: string | null): string {
  if (equipments.length == 0 || equipments.indexOf(EquipmentTypeOptions.no) >= 0)
    return noEquipmentString ?? "None"
  if (equipments.indexOf(EquipmentTypeOptions.heavy_dumbbells) >= 0)
    return "Heavy Dumbbells"
  return "Dumbbells"
}

export const SCHEDULE_CLASS_PREJOIN_BUFFER = getDurationMs(Duration.m10)


export const ALL_ID = "all"
