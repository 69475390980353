import { all, call, put, select, takeLatest, throttle } from "redux-saga/effects"
import rsf from "../../redux/rsf"
import { submitFeedback, submitFeedbackError, submitFeedbackSuccess, updateStars } from "./reviewSlice"
import { RootState } from "../../redux/store"
import { ErrorInfo, GENERIC_ERROR } from "../../redux/types"
import { getUserProfile, instanceOfFirestoreError } from "../../utils"
import { PayloadAction } from "@reduxjs/toolkit"
import firebase from "firebase"


const reviewState = (state: RootState) => state.reviewWorkout


function* updateStarsSaga({ payload }: PayloadAction<number>) {
  try {
    let reviewStateData = yield select(reviewState)
    if (reviewStateData.rating > 0 && reviewStateData.rating <= 5) {
      yield updateReview(reviewStateData.workoutId, reviewStateData.isGroup, reviewStateData.rating, false, reviewStateData.feedbackMessage)
    }
  } catch (e) {
    console.log("ss")
    console.error("updateStarsSaga: " + e.toString(), e)
  }
}

function* submitFeedbackSaga({ payload }: PayloadAction<string>) {
  try {
    console.log("submitFeedbackSaga")
    let reviewStateData = yield select(reviewState)
    if (reviewStateData.rating > 0 && reviewStateData.rating <= 5) {
      yield updateReview(reviewStateData.workoutId, reviewStateData.isGroup, reviewStateData.rating, true, reviewStateData.feedbackMessage)
      yield put(submitFeedbackSuccess())
    } else {
      yield put(submitFeedbackError({ message: "Please select a star rating!", errorTime: Date.now() }))
    }
  } catch (error) {
    console.log("fetchWorkoutSaga - error: " + error.toString())

    let errorInfo: ErrorInfo = {
      message: GENERIC_ERROR
    }

    if (instanceOfFirestoreError(error)) {
      errorInfo.message = error.message
      errorInfo.code = error.code
    }

    errorInfo.errorTime = Date.now()
    yield put(submitFeedbackError(errorInfo))
  }
}

function* updateReview(workoutId: string, isGroup: boolean, rating: number, userSubmit: boolean, feedback?: string | null) {
  yield call(
    rsf.firestore.addDocument,
    `workouts/${workoutId}/reviews`,
    {
      uid: getUserProfile()?.uid,
      isGroup,
      rating,
      feedback: feedback ?? "",
      userSubmit,
      reviewTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    }
  )
}


export default function* soloRootSaga() {
  yield all([
    throttle(1000, updateStars, updateStarsSaga),
    takeLatest(submitFeedback, submitFeedbackSaga)
  ])
}