import { all, call, put, take, takeLatest } from "redux-saga/effects"
import rsf from "../../../redux/rsf"
import { PayloadAction } from "@reduxjs/toolkit"
import {
  checkMembership,
  showMembershipPlans,
  startCheckout,
  stripeHandoverFailed,
  stripeHandoverSuccess,
  checkMembershipError
} from "./activateMembershipSlice"
import { GENERIC_ERROR, getErrorInfo } from "../../../redux/types"
import { loadStripe } from "@stripe/stripe-js"
import { delay, fullLink } from "../../../utils"
import { WORKOUTS_HOME } from "../../routing/Locations"

import firebase from "firebase"
import { STRIPE_DATA } from "../../../env"


function* checkoutSaga({ payload }: PayloadAction<string>) {
  try {
    const checkout = {
      price: payload,
      success_url: `${fullLink(WORKOUTS_HOME)}?stripe=success`,
      cancel_url: `${fullLink(WORKOUTS_HOME)}?stripe=cancel`,
      trial_from_plan: true,
      allow_promotion_codes: true
    }

    //check for past subscriptions for current user and set trial as false (JIRA: HI-212)
    const subscriptionRef = getSubscriptionRef()
    const snapshot: firebase.firestore.QuerySnapshot = yield call(rsf.firestore.getCollection, subscriptionRef)
    const subscriptionList = snapshot.docs.filter((doc) => doc.exists)
    if (subscriptionList.length) {
      checkout.trial_from_plan = false
    }

    const docRef = yield call(rsf.firestore.addDocument, `users/${rsf.app.auth().currentUser?.uid}/checkout_sessions`, checkout)
    // @ts-ignore
    const channel = rsf.firestore.channel(docRef, "document")

    while (true) {
      const sessionDoc = yield take(channel)
      const { sessionId } = sessionDoc.data()
      //console.log(sessionId);
      if (sessionId) {
        yield subscribe(sessionId)
        break
      }
    }

  } catch (error) {
    // Handle Errors here.
    const code = error.code
    let message = error.message
    if (!message) {
      message = GENERIC_ERROR
    }
    yield put(stripeHandoverFailed({ code, message }))
  }
}

function* subscribe(sessionId: string) {
  try {
    const stripe = yield call(loadStripe, STRIPE_DATA.publishableKey)
    if (stripe) {
      const { error } = yield call(stripe.redirectToCheckout, { sessionId })

      if (error) {
        yield put(stripeHandoverFailed({ message: error }))
      } else {
        yield put(stripeHandoverSuccess())
      }
    }
  } catch (error) {
    console.log(error)
    yield put(stripeHandoverFailed(getErrorInfo(error)))
  }
}

function* checkMembershipAndShowPlansSaga() {
  try {
    const isMember = yield checkIfPaidMember()
    yield put(showMembershipPlans(!isMember))
  } catch (error) {
    yield put(checkMembershipError(getErrorInfo(error)))
  }
}


async function waitForAuthIfNeeded(maxMs: number) {
  let maxAuthWaitSecs = maxMs
  while (firebase.auth().currentUser == null && maxAuthWaitSecs > 0) {
    await delay(1000);
    maxAuthWaitSecs -= 1000
  }
}


export async function checkIfPaidMember() {
  await waitForAuthIfNeeded(10000);
  const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult(true)
  return idTokenResult?.claims.stripeRole === "basic" || idTokenResult?.claims.memberRole === 'early_bird' || true // TODO remove this when enabling payments
}

export default function* membershipRootSaga() {
  yield all([
    takeLatest(startCheckout, checkoutSaga),
    takeLatest(checkMembership, checkMembershipAndShowPlansSaga)
  ])
}

function getSubscriptionRef() {
  return rsf.app.firestore().collection(`users/${rsf.app.auth().currentUser?.uid}/subscriptions`)
}
