import firebase from "firebase"
import "@firebase/firestore"
import ReduxSagaFirebase from "redux-saga-firebase"
import { isBrowser } from "../utils"
import { FIREBASE_OPTIONS } from "../env"

const firebaseApp = firebase.initializeApp(FIREBASE_OPTIONS)

if(isBrowser) {
  firebaseApp.analytics()
}

const rsf = new ReduxSagaFirebase(firebaseApp)
rsf.region = "asia-east2"

export default rsf