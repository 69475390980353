import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RegisterDetails } from "./types"
import { ErrorInfo, Profile, Resource, Status } from "../../redux/types"

const initialState: Resource<Profile> = {
  status: Status.INIT
}

export const registerSlice = createSlice({
  name: "register",
  initialState: initialState,
  reducers: {
    reset: state => initialState,
    register: (state, action: PayloadAction<RegisterDetails>) => {
      state.error = null
      state.status = Status.LOADING
    },
    registerFailure: (state, action: PayloadAction<ErrorInfo>) => {
      state.data = null
      state.error = action.payload
      state.status = Status.ERROR
    },
    registerSuccess: (state, action: PayloadAction<Profile>) => {
      state.data = action.payload
      state.error = null
      state.status = Status.FINISHED
    }
  }
})

export const {
  reset,
  register,
  registerFailure,
  registerSuccess
} = registerSlice.actions

export const selectNewUserResource = (state: { profileResource: Resource<Profile> }) => state.profileResource
export default registerSlice.reducer