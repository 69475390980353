import { ErrorInfo } from "../../../redux/types"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ActivateMembershipState } from "./types"

const initialState: ActivateMembershipState = {
  loading: false,
  showMembershipPlans: false,
  membershipCheckTimestamp: 0
}

export const activateMembershipSlice = createSlice({
  name: "activateMembership",
  initialState: initialState,
  reducers: {
    checkMembership: state => {
      state.loading = true
    },
    startCheckout: (state, action: PayloadAction<string>) => {
      state.loading = true
      state.error = null
    },
    stripeHandoverSuccess: state => {
      state.error = null
    },
    stripeHandoverFailed: (state, action: PayloadAction<ErrorInfo>) => {
      state = initialState
      state.error = action.payload
    },
    checkMembershipError: (state, action: PayloadAction<ErrorInfo>) => {
      state = initialState
      state.error = action.payload
    },
    showMembershipPlans: (state, action: PayloadAction<boolean>) => {
      state.membershipCheckTimestamp = Date.now()
      state.showMembershipPlans = action.payload
    },
    clean: state => initialState
  }
})

export const {
  startCheckout,
  stripeHandoverSuccess,
  stripeHandoverFailed,
  showMembershipPlans,
  checkMembership,
  checkMembershipError,
  clean
} = activateMembershipSlice.actions

export default activateMembershipSlice.reducer