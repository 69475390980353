import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CountdownData, GroupInfo, GroupWorkoutInit, GroupWorkoutState } from "./types"
import { AlertInfo, ErrorInfo, PlaybackInfo } from "../../../redux/types"
import { JoinGroupInfo } from "../state"

const ADD_CALENDAR_BUFFER_TIME = 25 * 60 * 1000 //25 minutes

const initialState: GroupWorkoutState = {
  showAddToCalender: false,
  allowMicOn: true,
  showStartUI: false,
  showWaitingRoom: false,
  showVideo: false,
  loading: true,
  startTime: -1,
  playedRatio: 0,
  showFeedback: false,
  videoVisible: false,
  numChatParticipants: 0,
  userInteractionComplete: false,
  mediaPermissionsSuccess: false,
  joinCall: false,
  classStarted: false,
  streamLink: null,
  twilioToken: null,
  alert: null
}

export const groupWorkoutSlice = createSlice({
  name: "groupWorkout",
  initialState: initialState,
  reducers: {
    init: (state, action: PayloadAction<GroupWorkoutInit>) => {
      state.groupId = action.payload.groupId
      state.userInteractionComplete = false
    },
    reset: () => initialState,
    groupInfoUpdate: (state, { payload }: PayloadAction<GroupInfo>) => {
      state.loading = false
      state.groupInfo = payload
      state.startTime = payload.startTime
      state.classStarted = payload.startTime > 0 && Date.now() >= payload.startTime
      state.showWaitingRoom = !state.showVideo && !state.showFeedback
      state.showStartUI = !payload.scheduled && payload.isHost && !state.classStarted
      state.joinCall = state.showStartUI || state.userInteractionComplete
      state.showAddToCalender = payload.scheduled && (payload.startTime - Date.now() >= ADD_CALENDAR_BUFFER_TIME)
    },
    startWorkout: state => state,
    alertUser: (state, { payload }: PayloadAction<AlertInfo>) => {
      state.alert = payload
    },
    userInteracted: state => state,
    userInteractedSuccess: state => {
      state.userInteractionComplete = true
      state.joinCall = true
      state.alert = null
    },
    joinGroupSuccess: (state, { payload }: PayloadAction<JoinGroupInfo>) => {
      state.streamLink = payload.videoUrl
      state.twilioToken = payload.token
      state.muxStream = payload.videoUrl.includes("mux")
      state.hlsStartLevel = state.muxStream ? undefined : -1
    },
    groupInfoUpdateFailure: (state, action: PayloadAction<ErrorInfo>) => {
      state.loading = false
      state.startTime = 0
      state.error = action.payload
    },
    countdownUpdate: (state, { payload }: PayloadAction<CountdownData>) => {
      state.countdown = payload
    },
    playVideo: state => {
      if (!(state.groupInfo?.alreadyEnded ?? false)) {
        state.countdown = null
        state.showVideo = true
        state.showWaitingRoom = false
        state.showFeedback = false
        state.allowMicOn = false
      }
    },
    videoPlaybackUpdate: (state, action: PayloadAction<PlaybackInfo>) => {
      state.playedRatio = action.payload.playedRatio
      state.videoVisible = action.payload.videoVisible
    },
    updateNumChatParticipants: (state, { payload }: PayloadAction<number>) => {
      state.numChatParticipants = payload
    },
    showFeedback: (state, { payload }: PayloadAction<boolean>) => {
      state.showFeedback = payload
      if (payload) {
        state.showWaitingRoom = false
        state.showVideo = false
        state.allowMicOn = true
      }
    },
    mediaPermissionsChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.mediaPermissionsSuccess = payload
    },
    clearAlert: (state) => {
      state.alert = null
    }
  }
})

export const {
  init,
  reset,
  startWorkout,
  userInteracted,
  joinGroupSuccess,
  groupInfoUpdate,
  groupInfoUpdateFailure,
  videoPlaybackUpdate,
  userInteractedSuccess,
  alertUser,
  updateNumChatParticipants,
  showFeedback,
  clearAlert,
  playVideo,
  countdownUpdate,
  mediaPermissionsChanged
} = groupWorkoutSlice.actions

export default groupWorkoutSlice.reducer