import { all, call, cancelled, put, race, select, take, takeLatest, throttle } from "redux-saga/effects"
import rsf from "../../redux/rsf"
import {
  clearWorkout,
  clientTimeOffsetUpdate,
  fetchWorkout,
  fetchWorkoutFailed,
  fetchWorkoutSuccess,
  playVideo,
  showFeedback,
  userInteractionToPlayVideo,
  videoPlaybackUpdate,
  waitCountdownUpdate
} from "./soloSlice"
import { ScheduleDateFormats, ScheduleInfo, WorkoutVideo } from "../browse/types"
import { getUserProfile, instanceOfFirestoreError } from "../../utils"
import { ErrorInfo, GENERIC_ERROR, PlaybackInfo } from "../../redux/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../redux/store"
import { workoutVideoTransformer } from "../browse/browseSaga"
import firebase from "firebase"
import { InitData, SoloState } from "./types"
import { clockOffsetSaga, countdown } from "../../redux/saga"
import moment from "moment"
import { clearLive, initLive, updatePlayedSeconds } from "../live/liveSlice"
import { logClassHistory } from "../account/accountSlice"


const browseState = (state: RootState) => state.browse
const soloState = (state: RootState) => state.solo
export const RESET_PLAYBACK_THRESHOLD = 2 * 60 * 60 * 1000 // 2 hours


// @ts-ignore
function* fetchWorkoutSaga({ payload }: PayloadAction<InitData>) {
  try {
    if (payload.timeSlotId)
      yield put(initLive(payload.timeSlotId))

    const [workout, scheduleInfo, clientTimeOffset, userWorkout, sid] = yield all([
      call(fetchWorkoutHelper, payload.wid),
      call(fetchScheduleInfo, payload.timeSlotId),
      call(clockOffsetSaga, true),
      call(rsf.firestore.getDocument, getUserWorkoutFirestorePath(payload.wid)),
      call(createSession, payload)
    ])

    if (scheduleInfo) {
      scheduleInfo.startTimeInMs = scheduleInfo.startTimeInMs + clientTimeOffset
      scheduleInfo.isLive = scheduleInfo.startTimeInMs >= Date.now()
      workout.scheduleInfo = scheduleInfo
    }

    const streamLink = yield fetchStreamLink(payload.wid, sid)
    const muxStream = streamLink?.includes("mux") ?? false

    const userWorkoutData = userWorkout.data()
    let playbackProgress = userWorkoutData?.playbackProgress ?? 0
    let shouldReset = playbackProgress >= 0.99 || (Date.now() - (userWorkoutData?.updateTime?.toMillis() ?? 0) >= RESET_PLAYBACK_THRESHOLD)

    if (shouldReset) {
      playbackProgress = 0
    }

    yield put(clientTimeOffsetUpdate(clientTimeOffset))
    yield put(fetchWorkoutSuccess({ workout, playbackProgress, streamLink, sid, muxStream, hlsStartLevel: muxStream ? undefined : -1 }))

    if (scheduleInfo) {
      yield call(classWaitTickerSaga, scheduleInfo.startTimeInMs)
    } else {
      yield call(attemptVideoPlaySaga)
    }


  } catch (error) {
    console.log("fetchWorkoutSaga - error: " + error.toString())

    let errorInfo: ErrorInfo = {
      message: GENERIC_ERROR
    }

    if (instanceOfFirestoreError(error)) {
      errorInfo.message = error.message
      errorInfo.code = error.code
    }

    yield put(fetchWorkoutFailed(errorInfo))
  }
}

function* classWaitTickerSaga(classStartInMs: number) {

  const countdownTime = Math.round((classStartInMs - Date.now()) / 1000)
  if (countdownTime > 0) {
    const chan = yield call(countdown, countdownTime, 1)
    try {
      while (true) {
        let seconds = yield take(chan)
        yield put(waitCountdownUpdate({
          countdown: moment.utc(seconds * 1000).format(seconds >= 60 ? ScheduleDateFormats.countdownMinSec : ScheduleDateFormats.countdownSec).toString(),
          showPlay: false
        }))
      }
    } finally {
      if (yield cancelled()) {
        chan.close()
        console.log("countdown cancelled")
      } else {
        yield call(attemptVideoPlaySaga)
      }
    }
  } else {
    yield call(attemptVideoPlaySaga)
  }
}

function* attemptVideoPlaySaga() {
  if (!userInteractionToPlayVideo) {
    yield put(waitCountdownUpdate({ showPlay: true }))
  } else {
    yield put(playVideo())
  }
}

function* videoPlaybackUpdateSaga({ payload }: PayloadAction<PlaybackInfo>) {
  try {
    let soloData = yield select(soloState)
    if (soloData.userWorkout) {

      // @ts-ignore
      yield call(rsf.firestore.setDocument, getUserWorkoutFirestorePath(soloData.userWorkout.workout.id),
        {
          playedSeconds: payload.playedSeconds,
          playbackProgress: payload.playedRatio,
          completed: payload.playedRatio >= 0.99,
          updateTime: firebase.firestore.FieldValue.serverTimestamp()
        })
    }
  } catch (error) {
    console.log("videoPlaybackUpdateSaga - error: " + error.toString())
  }
}

function* videoPlaybackFeedbackSaga({ payload }: PayloadAction<PlaybackInfo>) {

  yield put(updatePlayedSeconds(payload.playedSeconds))

  let soloData: SoloState = yield select(soloState)


  if (soloData.userWorkout) {

    if (soloData.classStartTimestamp)
      yield put(logClassHistory({
        wid: soloData.userWorkout.workout.id,
        classTimestamp: soloData.classStartTimestamp,
        scheduledClass: (soloData.userWorkout.workout.scheduleInfo?.startTimeInMs ?? 0) > 0,
        groupClass: false,
        timeSlotId: soloData.userWorkout.workout.scheduleInfo?.id,
        playbackInfo: payload
      }))


    if (payload.playedRatio >= 0.999)
      yield put(showFeedback(true))
  }
}

export function* fetchWorkoutHelper(id: string) {
  let workout = yield fetchWorkoutFromBrowseSlice(id)
  if (!workout)
    workout = yield fetchWorkoutFromRemote(id)
  return Object.assign({}, workout)
}

function* fetchWorkoutFromRemote(id: string) {
  const snapshot = yield call(rsf.firestore.getDocument, `/workouts/${id}`)
  if (snapshot)
    return workoutVideoTransformer(snapshot.data(), snapshot.id)
  return null
}

function* fetchWorkoutFromBrowseSlice(id: string) {
  let browseData = yield select(browseState)
  return browseData.allWorkouts.find((w: WorkoutVideo) => w.id == id)
}

function* createSession(data: InitData) {
  const sessionsCollectionRef = `${getUserWorkoutFirestorePath(data.wid)}/sessions`
  const doc = yield call(rsf.firestore.addDocument, sessionsCollectionRef, {
    startTime: firebase.firestore.FieldValue.serverTimestamp(),
    timeSlotId: data.timeSlotId ?? null
  })
  return doc.id
}

function* fetchScheduleInfo(timeSlotId?: string | null) {
  if (timeSlotId) {
    const snapshot = yield call(rsf.firestore.getDocument, `/schedule/${timeSlotId}`)
    if (snapshot.exists) {
      const scheduleInfo: ScheduleInfo = {
        ctaState: "join",
        isLive: true,
        startTimeInMs: snapshot.data().classTimestamp.toMillis(),
        id: snapshot.id
      }
      return scheduleInfo
    }
  }
  return null
}

function* fetchStreamLink(wid: string, sid: string) {
  const sessionsCollectionRef = `${getUserWorkoutFirestorePath(wid)}/sessions`
  // @ts-ignore
  const channel = rsf.firestore.channel(`${sessionsCollectionRef}/${sid}`, "document")
  while (true) {
    const doc = yield take(channel)
    const data = doc.data()
    if (data.streamLink) {
      channel.close()
      return data.streamLink
    }
  }
}


function getUserWorkoutFirestorePath(wid: string): string {
  const uid = getUserProfile()?.uid ?? ""
  return `/users/${uid}/workouts/${wid}`
}


export default function* soloRootSaga() {
  yield all([
    takeLatest(fetchWorkout, function* (action: PayloadAction<InitData>) {
      yield race({
        task: call(fetchWorkoutSaga, action),
        cancel: take(clearWorkout)
      })
    }),
    throttle(5000, videoPlaybackUpdate, videoPlaybackUpdateSaga),
    takeLatest(videoPlaybackUpdate, videoPlaybackFeedbackSaga),
    takeLatest(clearWorkout, function* () {
      yield put(clearLive())
    })
  ])
}