import { all, call, cancelled, fork, put, race, select, take, takeLatest, throttle } from "redux-saga/effects"
import { channel } from "redux-saga"
import rsf from "../../redux/rsf"
import {
  clearLive,
  initLive,
  shoutoutClicked,
  showShoutout,
  updateHausmatesCount,
  updateScheduledStatus
} from "./liveSlice"
import { PayloadAction } from "@reduxjs/toolkit"
import { delay, getUserProfile } from "../../utils"
import { RootState } from "../../redux/store"
import { LiveState, ShoutoutDisplay } from "./types"
import firebase from "firebase"
import { clockOffsetSaga, countdown } from "../../redux/saga"

const SHOUTOUT_SECONDS_BUFFER = 5

const liveState = (state: RootState) => state.live

let shoutoutIdsShown: string[] = []
let botShoutouts: ShoutoutDisplay[] = []

function* initSaga({ payload }: PayloadAction<string>) {

  const chan = yield call(channel)
  yield fork(handleShoutout, chan)

  yield fork(function* () {
    yield race({
      task: call(listenForLiveDataChange, payload, chan),
      cancel: take(clearLive)
    })
  })

  yield fork(function* () {
    yield race({
      task: call(liveBotTickerSaga, chan),
      cancel: take(clearLive)
    })
  })

}

function* liveBotTickerSaga(channel: any) {
  const chan = yield call(countdown, 31536000, 1)
  try {
    while (true) {
      yield take(chan)
      const liveData: LiveState = yield select(liveState)
      for (const s of botShoutouts) {
        if (Math.abs(liveData.playedSeconds - s.secondToShow!) < 2 && shoutoutIdsShown.indexOf(s.id) < 0) {
          yield put(channel, s)
          shoutoutIdsShown.push(s.id)
        }
      }
    }
  } finally {
    if (yield cancelled()) {
      chan.close()
      console.log("countdown cancelled")
    }
  }
}

function* listenForLiveDataChange(liveId: string, channel: any) {

  // @ts-ignore
  const liveChannel = rsf.firestore.channel(`live/${liveId}`, "document")
  while (true) {
    let liveData: LiveState = yield select(liveState)

    const doc = yield take(liveChannel)
    const data = doc.data()
    yield put(updateScheduledStatus(data.scheduledClass ?? false))
    yield put(updateHausmatesCount(data.scheduledClass ? (data.hausmatesCount ?? 0) : 0))
    const shoutouts: ShoutoutDisplay[] = data.shoutouts ?? []
    botShoutouts = shoutouts.filter(s => (s.secondToShow ?? -1) >= 0 && (s.secondToShow! - liveData.playedSeconds > -SHOUTOUT_SECONDS_BUFFER))

    for (const s of shoutouts) {
      if (s.timestamp) {
        let offset: number = yield call(clockOffsetSaga, false)
        const shoutoutTime = s.timestamp.toMillis() + offset
        if (Date.now() > shoutoutTime && Date.now() - shoutoutTime <= 3000 && shoutoutIdsShown.indexOf(s.id) < 0) {
          yield put(channel, s)
          shoutoutIdsShown.push(s.id)
        }
      }
    }
  }

}

function* handleShoutout(chan: any) {
  while (true) {
    const shoutout: ShoutoutDisplay = yield take(chan)
    yield put(showShoutout(shoutout))
    yield call(delay, 3000)
  }
}


function* shoutoutSaga() {
  const user = getUserProfile()
  if (user) {
    let liveData: LiveState = yield select(liveState)
    try {
      yield call(rsf.firestore.addDocument, `live/${liveData.liveId}/users/${user.uid}/shoutouts`, { shoutoutTimestamp: firebase.firestore.FieldValue.serverTimestamp() })
    } catch (e) {
      console.error("Cant write to shoutouts", e)
    }
  }
}

function* clearSaga() {
  shoutoutIdsShown = []
  botShoutouts = []
}


export default function* liveRootSaga() {
  yield all([
    takeLatest(initLive, initSaga),
    takeLatest(clearLive, clearSaga),
    throttle(3000, shoutoutClicked, shoutoutSaga)
  ])
}