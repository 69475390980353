import { all, call, put, takeLatest } from "redux-saga/effects"
import rsf from "../../../redux/rsf"
import { createGroupFailure, createGroupSuccess, createGroupWorkout } from "./workoutModeSlice"
import { PayloadAction } from "@reduxjs/toolkit"
import { WorkoutVideo } from "../types"
import { ErrorInfo, GENERIC_ERROR, GroupMemberType } from "../../../redux/types"
import { fullLink, getUserProfile } from "../../../utils"
import firebase from "firebase"
import { getGroupUrl } from "../../routing/Locations"
import { getInviteMessage } from "./shareMessageGenerator"


function* createNewGroupWorkoutSaga({ payload }: PayloadAction<WorkoutVideo>) {
  try {
    const profile = getUserProfile()!
    const scheduled = (payload.scheduleInfo?.startTimeInMs ?? 0) > 0
    const doc = yield call(rsf.firestore.addDocument, "groupworkouts", {
      videoId: payload.id,
      videoDifficulty: payload.mainDifficulty,
      videoRating: payload.rating,
      videoSubtitle: payload.subtitle,
      videoThumbnailUrl: payload.thumbnailUrl,
      videoTitle: payload.title,
      videoGifUrl: payload.gifUrl,
      videoCategories: payload.categories,
      videoDurationCategory: payload.durationCategory,
      videoEquipments: payload.equipments,
      videoDescription: payload.description,
      hostName: profile.displayName,
      members: { [profile.uid]: GroupMemberType.host.toString() },
      creationTime: firebase.firestore.FieldValue.serverTimestamp(),
      hostId: profile.uid,
      hostStartTimestamp: null,
      scheduleId: payload.scheduleInfo?.id ?? null,
      scheduleClassTimestamp: payload.scheduleInfo?.startTimeInMs ? firebase.firestore.Timestamp.fromMillis(payload.scheduleInfo!.startTimeInMs) : null,
      scheduled
    })

    yield onCreateGroupSuccess(payload, doc.id, scheduled && payload.scheduleInfo!.ctaState == "invite")
  } catch (error) {
    yield put(createGroupFailure(toErrorInfo(error)))
  }
}

function* createScheduledGroupSaga({ payload }: PayloadAction<WorkoutVideo>) {
  try {
    const profile = getUserProfile()!
    const groupRef = rsf.app.firestore().collection("groupworkouts")
    // @ts-ignore
    const snapshot = yield call(rsf.firestore.getCollection, groupRef.where("hostId", "==", profile.uid).where("scheduleId", "==", payload.scheduleInfo!.id).limit(1))
    if (snapshot.empty) {
      yield createNewGroupWorkoutSaga({ payload, type: createGroupWorkout.toString() })
    } else {
      const doc = snapshot.docs[0]
      yield onCreateGroupSuccess(payload, doc.id, payload.scheduleInfo!.ctaState == "invite")
    }
  } catch (error) {
    yield put(createGroupFailure(toErrorInfo(error)))
  }
}

function* createGroupWorkoutSaga(action: PayloadAction<WorkoutVideo>) {
  if (action.payload.scheduleInfo) {
    yield createScheduledGroupSaga(action)
  } else {
    yield createNewGroupWorkoutSaga(action)
  }
}

function* onCreateGroupSuccess(w: WorkoutVideo, gid: string, inviteFriends: boolean) {
  const profile = getUserProfile()!
  const link = getGroupUrl(w.id, gid)
  yield put(createGroupSuccess({
    workout: w,
    groupId: gid,
    groupUrl: link,
    inviteFriends: inviteFriends,
    inviteText: getInviteMessage(profile.displayName, w.title, fullLink(link), w.scheduleInfo?.startTimeInMs, w.equipments)
  }))
}

function toErrorInfo(error: any): ErrorInfo {
  console.error(JSON.stringify(error))
  const code = error.code
  let message = error.message
  if (!message) {
    message = GENERIC_ERROR
  }
  return { code, message }
}


export default function* workoutModeRootSaga() {
  yield all([
    takeLatest(createGroupWorkout, createGroupWorkoutSaga)
  ])
}