import { instanceOfFirestoreError } from "../utils"

export enum Status {
  INIT = "INIT",
  LOADING = "LOADING",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export interface Resource<T> {
  data?: T | null
  error?: ErrorInfo | null
  status: Status
}

export interface ErrorInfo {
  code?: string | null
  message: string
  errorTime?: number | null
}

export interface AlertInfo {
  title: string
  message: string
}

export interface Profile {
  uid: string
  displayName: string
  email: string
  onboardingComplete: boolean
}

export const GENERIC_ERROR = "Something went wrong. Please try again!"

export interface PlaybackInfo {
  playedSeconds: number
  playedRatio: number
  videoVisible: boolean
}

export enum GroupMemberType {
  host = "host",
  participant = "participant"
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
}

export function getErrorInfo(error: any): ErrorInfo {
  let errorInfo: ErrorInfo = {
    message: GENERIC_ERROR,
    errorTime: Date.now()
  }

  if (instanceOfFirestoreError(error)) {
    errorInfo.message = error.message
    errorInfo.code = error.code
  }

  return errorInfo
}