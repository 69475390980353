import { getUserProfile, isValidString } from "../../utils"
import firebase from "firebase"
import { all, put, select, takeEvery } from "redux-saga/effects"
import { loginSuccess, logoutSuccess } from "../login/loginSlice"
import { workoutSelected, toggleShowSchedule, sortClicked, sortWorkouts, filterValueChanged } from "../browse/browseSlice"
import { BrowseState, FilterType, SortType, WorkoutSelectedData, WorkoutVideo } from "../browse/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { changeEmailSuccess, resetPasswordEmailSent, uploadProfilePicSuccess, logClassHistory } from "../account/accountSlice"
import { RootState } from "../../redux/store"
import { CreateGroupSuccessData } from "../browse/ChooseWorkoutMode/types"
import { createGroupSuccess } from "../browse/ChooseWorkoutMode/workoutModeSlice"
import { userInteractedSuccess } from "../videochat/redux/groupSlice"
import { GroupWorkoutState } from "../videochat/redux/types"
import { LogClassData } from "../account/types"
import { registerSuccess, registerFailure } from "../register/registerSlice"
import { ErrorInfo } from "../../redux/types"


const browseState = (state: RootState) => state.browse
const groupWorkoutState = (state: RootState) => state.groupWorkouts
const ON_DEMAND = "on-demand"
const CLASS_TYPE_ON_DEMAND_SOLO = "on_demand_solo"
const CLASS_TYPE_ON_DEMAND_FRIENDS = "on_demand_friends"
const CLASS_TYPE_SCHEDULED_SOLO = "scheduled_solo"
const CLASS_TYPE_SCHEDULED_FRIENDS = "scheduled_friends"




function loginSaga() {
  const profile = getUserProfile()
  if (profile) {
    logUserId()
    firebase.analytics().logEvent("login", {})

  } else {
    // @ts-ignore
    firebase.analytics().setUserId(null)
    firebase.analytics().logEvent("logout", {})
  }
}

function registerSuccessSaga() {
  logUserId()
  firebase.analytics().logEvent("register_success", {})
}

function registerFailureSaga({payload}: PayloadAction<ErrorInfo>) {
  firebase.analytics().logEvent("register_failure", {code: payload.code ?? "unknown"})
}

function* viewClassSaga({ payload }: PayloadAction<WorkoutSelectedData>) {
  logUserId()
  if(payload.type === "friends-invite") {
    logInviteFriends(payload.workout, !(payload.fromScheduleList ?? false), payload.fromScheduleList ?? false, false)
  } else {
    let data: any = {
      ...getWorkoutProperties(payload.workout),
      fromScheduleList: payload.fromScheduleList ?? false
    }

    firebase.analytics().logEvent("view_class", data)
  }
}

function logInviteFriends(workout: WorkoutVideo, fromCard: boolean, fromSchedule: boolean, fromClassDetail: boolean) {
  firebase.analytics().logEvent("invite_friends", {
    ...getWorkoutProperties(workout),
    invitedFrom: fromCard ? "class_card" : (fromSchedule ? "schedule_list" : "class_detail")
  })
}

function uploadProfilePicSaga() {
  logUserId()
  firebase.analytics().logEvent("changed_profile_pic")
}

function resetPasswordSaga() {
  logUserId()
  firebase.analytics().logEvent("reset_password")
}

function changeEmailSaga() {
  logUserId()
  firebase.analytics().logEvent("changed_email")
}

function* toggleFullScheduleSaga() {
  let browseData: BrowseState = yield select(browseState)
  firebase.analytics().logEvent(browseData.showScheduleActive ? "open_full_schedule" : "close_full_schedule")
}

function* groupCreatedSaga({ payload }: PayloadAction<CreateGroupSuccessData>) {
  let data: any = {
    ...getWorkoutProperties(payload.workout),
    inviteFriends: payload.inviteFriends,
    classType: getClassType(true, payload.workout.scheduleInfo?.id)
  }
  firebase.analytics().logEvent("group_class_created", data)
}

function* groupButtonClickedSaga() {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState)
  let data: any = {
    wid: groupState.groupInfo?.videoId,
    gid: groupState.groupId,
    scheduledClass: groupState.groupInfo?.scheduled ?? false,
    numGroupHausmates: groupState.numChatParticipants,
    classType: getClassType(true, groupState.groupInfo?.scheduleId)
  }
  data["timeSlotId"] = groupState.groupInfo?.scheduleId ?? ON_DEMAND

  if(groupState.groupInfo?.isHost && !groupState.groupInfo.scheduled) {
    firebase.analytics().logEvent("host_started_class", data)
  } else {
    firebase.analytics().logEvent("joined_group_class", data)
  }
}

function* sortClickedSaga() {
  let browseData: BrowseState = yield select(browseState)
  let data: any = {}
  addFilterData(browseData, data)

  firebase.analytics().logEvent("sort_clicked", data)
}

function* sortWorkoutsSaga({payload}: PayloadAction<SortType>) {
  let browseData: BrowseState = yield select(browseState)
  let data: any = {}
  addFilterData(browseData, data)
  data["sort_type"] = payload.toString()

  firebase.analytics().logEvent("sort_classes", data)
}

function* filterWorkoutsSaga() {
  let browseData: BrowseState = yield select(browseState)
  let data: any = {}
  addFilterData(browseData, data)

  firebase.analytics().logEvent("filter_classes", data)
}


let progressLogged: number[] = []
let logWid: string | null = null
function* classProgressSaga({payload}: PayloadAction<LogClassData>) {
  if (logWid !== payload.wid) {
    progressLogged = []
    logWid = payload.wid
  }

  const eventParams = {
    wid: payload.wid,
    scheduledClass: payload.scheduledClass,
    groupClass: payload.groupClass,
    timeSlotId: payload.timeSlotId ?? ON_DEMAND,
    playedSeconds: payload.playbackInfo.playedSeconds,
    playedRatio: payload.playbackInfo.playedRatio,
    classType: getClassType(payload.groupClass, payload.timeSlotId)
  }

  if(progressLogged.length === 0) {
    firebase.analytics().logEvent("class_started", eventParams)
    progressLogged.push(0)
  }

  checkAndLogClassProgress(progressLogged, .10, .25, "class_progress_10", eventParams)
  checkAndLogClassProgress(progressLogged, .25, .50, "class_progress_25", eventParams)
  checkAndLogClassProgress(progressLogged, .50, .75, "class_progress_50", eventParams)
  checkAndLogClassProgress(progressLogged, .75, .90, "class_progress_75", eventParams)
  checkAndLogClassProgress(progressLogged, .90, .95, "class_progress_90", eventParams)
  checkAndLogClassProgress(progressLogged, .95, .995, "class_progress_95", eventParams)
  checkAndLogClassProgress(progressLogged, .995, 1.05, "class_progress_100", eventParams)

}

function checkAndLogClassProgress(progressLogged: number[], lowerProgressBound: number, upperProgressBound: number, event: string, eventParams: any) {
  if(progressLogged.indexOf(lowerProgressBound) < 0 && eventParams.playedRatio >= lowerProgressBound && eventParams.playedRatio < upperProgressBound) {
    firebase.analytics().logEvent(event, eventParams)
    progressLogged.push(lowerProgressBound)
  }
}

function addFilterData(browseData: BrowseState, data: any) {
  for (let [key, value] of Object.entries(browseData.activeFilters)) {
    // @ts-ignore
    for (let [subFilterKey, subFilterValue] of Object.entries(value)) {
      if(!subFilterKey.includes("display")) {
        data[`f_${key}_${subFilterKey}`] = subFilterValue
      }
    }

  }
}

export function logUserId() {
  const profile = getUserProfile()
  if (profile)
    firebase.analytics().setUserId(profile.uid)
}

function getWorkoutProperties(workout: WorkoutVideo): any {
  const { id, localImage, gifUrl, thumbnailUrl, scheduleInfo, pageTitle, rating, description, scheduleTitle, categories, instructors, equipments, difficulties, ...rest } = workout
  let data: any = {
    wid: id,
    ...rest
  }

  addArrayParams(data, categories, "category", "categories")
  addArrayParams(data, instructors, "instructor", "instructors")
  addArrayParams(data, equipments, "equipment", "equipments")
  addArrayParams(data, difficulties, "difficulty", "difficulties")

  data["scheduledClass"] = (workout.scheduleInfo?.startTimeInMs ?? 0) > 0

  data["timeSlotId"] = scheduleInfo?.id ?? ON_DEMAND

  if (scheduleInfo) {
    data["countdownActive"] = scheduleInfo.countdown && !scheduleInfo.isLive
    data["classStarted"] = scheduleInfo.isLive
  }


  return data
}

function addArrayParams(data: any, items:any[], singleItemKey: string, listKey: string) {
  items.forEach(i => {
    data[`${singleItemKey}_${i}`] = true
  })
  data[listKey] = items.toString()
}

function getClassType(group: boolean, timeslotId?: string | null) : string {
  if(!isValidString(timeslotId) || timeslotId === ON_DEMAND) {
    return group ? CLASS_TYPE_ON_DEMAND_FRIENDS : CLASS_TYPE_ON_DEMAND_SOLO
  }

  return group ? CLASS_TYPE_SCHEDULED_FRIENDS : CLASS_TYPE_SCHEDULED_SOLO
}


export default function* analyticsRootSaga() {
  yield all([
    takeEvery([loginSuccess, logoutSuccess], loginSaga),
    takeEvery(workoutSelected, viewClassSaga),
    takeEvery(uploadProfilePicSuccess, uploadProfilePicSaga),
    takeEvery(resetPasswordEmailSent, resetPasswordSaga),
    takeEvery(changeEmailSuccess, changeEmailSaga),
    takeEvery(toggleShowSchedule, toggleFullScheduleSaga),
    takeEvery(createGroupSuccess, groupCreatedSaga),
    takeEvery(userInteractedSuccess, groupButtonClickedSaga),
    takeEvery(sortClicked, sortClickedSaga),
    takeEvery(sortWorkouts, sortWorkoutsSaga),
    takeEvery(filterValueChanged, filterWorkoutsSaga),
    takeEvery(logClassHistory, classProgressSaga),
    takeEvery(registerSuccess, registerSuccessSaga),
    takeEvery(registerFailure, registerFailureSaga)

  ])
}