import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CountdownData, InitData, SoloState, UserWorkout } from "./types"
import { ErrorInfo, PlaybackInfo } from "../../redux/types"

const initialState: SoloState = {
  playbackStartPosition: -1,
  clientTimeOffset: 0,
  playVideo: false,
  showVideo: false,
  showWaitingRoom: false,
  loading: false,
  showFeedback: false,
  userWorkout: null,
  countdown: null,
  showWaitRoomPlay: false,
  playerPlaying: false,
  alwaysShowControls: false,
  error: null
}

export let userInteractionToPlayVideo = false

export function userInteractedToPlayVideo() {
  userInteractionToPlayVideo = true
}

export const soloSlice = createSlice({
  name: "solo",
  initialState: initialState,
  reducers: {
    fetchWorkout: (state, action: PayloadAction<InitData>) => {
      state.loading = true
    },
    fetchWorkoutSuccess: (state, action: PayloadAction<UserWorkout>) => {
      state.userWorkout = action.payload
      state.error = null
      state.showVideo = !action.payload.workout.scheduleInfo

      const startTimestamp = state.userWorkout?.workout.scheduleInfo?.startTimeInMs ?? 0
      if(startTimestamp > 0) {
        const playbackStartDiff = (Date.now() - startTimestamp) / 1000
        if (playbackStartDiff > 2) {
          state.playbackStartPosition = playbackStartDiff
        }
      }
    },
    fetchWorkoutFailed: (state, action: PayloadAction<ErrorInfo>) => {
      state.loading = false
      state.userWorkout = null
      state.error = action.payload
    },
    clientTimeOffsetUpdate: (state, { payload }: PayloadAction<number>) => {
      state.clientTimeOffset = payload
    },
    waitCountdownUpdate: (state, { payload }: PayloadAction<CountdownData>) => {
      state.showVideo = true
      state.loading = false
      state.countdown = payload.countdown
      state.showWaitRoomPlay = payload.showPlay
      state.showWaitingRoom = ((payload.countdown?.length ?? 0) > 0) || payload.showPlay
    },
    videoPlaybackUpdate: (state, action: PayloadAction<PlaybackInfo>) => {
    },
    playVideo: (state) => {
      state.loading = false
      state.showVideo = true
      state.playVideo = true
      state.showFeedback = false
      state.showWaitingRoom = false
      state.alwaysShowControls = false
      state.classStartTimestamp = state.userWorkout?.workout.scheduleInfo?.startTimeInMs ?? Date.now()

      const playbackStartDiff = (Date.now() - state.classStartTimestamp)/1000
      if(playbackStartDiff > 2) {
        state.playbackStartPosition = playbackStartDiff
      }
    },
    waitRoomPlayClicked: (state) => {
      state.showWaitingRoom = false
      state.playVideo = true
      state.alwaysShowControls = false
      state.classStartTimestamp = state.userWorkout?.workout.scheduleInfo?.startTimeInMs ?? Date.now()
      userInteractionToPlayVideo = true

      const playbackStartDiff = (Date.now() - state.classStartTimestamp)/1000
      if(playbackStartDiff > 2) {
        state.playbackStartPosition = playbackStartDiff
      }
    },
    showFeedback: (state, { payload }: PayloadAction<boolean>) => {
      state.showFeedback = payload
      if (payload) {
        state.showVideo = false
        state.showWaitingRoom = false
        state.alwaysShowControls = true
      }
    },
    playerOnPlay: (state) => {
      state.playerPlaying = true
      state.alwaysShowControls = false
    },
    playerOnPause: (state) => {
      state.playerPlaying = false
      state.alwaysShowControls = true
    },
    togglePlayerPlay: (state) => {
      state.playVideo = !state.playerPlaying
      state.playerPlaying = !state.alwaysShowControls
    },
    clearWorkout: (state) => initialState
  }
})

export const {
  fetchWorkout,
  fetchWorkoutSuccess,
  fetchWorkoutFailed,
  clientTimeOffsetUpdate,
  videoPlaybackUpdate,
  playVideo,
  waitCountdownUpdate,
  waitRoomPlayClicked,
  showFeedback,
  playerOnPause,
  playerOnPlay,
  togglePlayerPlay,
  clearWorkout
} = soloSlice.actions

export default soloSlice.reducer