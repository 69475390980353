import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"

import createSagaMiddleware from "redux-saga"
import rootSaga from "./saga"
import { loginSlice } from "../features/login/loginSlice"
import { registerSlice } from "../features/register/registerSlice"
import { browseSlice } from "../features/browse/browseSlice"
import { soloSlice } from "../features/solo/soloSlice"
import { groupWorkoutSlice } from "../features/videochat/redux/groupSlice"
import { workoutModeSlice } from "../features/browse/ChooseWorkoutMode/workoutModeSlice"
import { reviewSlice } from "../features/review/reviewSlice"
import { routeSlice } from "../features/routing/routeSlice"
import { liveSlice } from "../features/live/liveSlice"
import { accountSlice } from "../features/account/accountSlice"
import { activateMembershipSlice } from "../features/browse/ActivateMembership/activateMembershipSlice"
import { membershipSlice } from "../features/membership/membershipSlice"

const rootReducer = combineReducers({
  login: loginSlice.reducer,
  register: registerSlice.reducer,
  browse: browseSlice.reducer,
  membership: membershipSlice.reducer,
  activateMembership: activateMembershipSlice.reducer,
  groupWorkouts: groupWorkoutSlice.reducer,
  solo: soloSlice.reducer,
  workoutMode: workoutModeSlice.reducer,
  reviewWorkout: reviewSlice.reducer,
  route: routeSlice.reducer,
  live: liveSlice.reducer,
  account: accountSlice.reducer
})

let sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware({
  thunk: false,
  immutableCheck: false,
  serializableCheck: false
}), sagaMiddleware]

const store = configureStore({
  reducer: rootReducer,
  middleware
})


sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;

export default store

