export const REGISTER_ERROR_EMAIL_USED = "auth/email-already-in-use"

export interface RegisterDetails {
  displayName: string
  email: string
  password: string
}

export interface RegisterFormErrors {
  nameError?: string | null
  emailError?: string | null
  passwordError?: string | null
}

