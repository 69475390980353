import firebase from "firebase"
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects"
import rsf from "../../redux/rsf"
import { login, loginFailure, loginSuccess, logout, logoutFailure, logoutSuccess, reset } from "./loginSlice"
import {
  LOGIN_ERROR_INVALID_EMAIL,
  LOGIN_ERROR_USER_NOT_FOUND,
  LOGIN_ERROR_WRONG_PASSWORD,
  LoginDetails
} from "./types"
import { PayloadAction } from "@reduxjs/toolkit"
import { clearUserProfile, profileTransformer, setUserProfile } from "../../utils"
import { GENERIC_ERROR } from "../../redux/types"
import { MAIN_DOMAIN } from "../routing/Locations"
import Cookies from "js-cookie"


const NO_ACCOUNT_MESSAGE = "This email does not have an account registered. Please create an account to continue."

export function addHausmateCookie() {
  Cookies.set("hausmate", "true", { domain: MAIN_DOMAIN, expires: 365 })

}

export function removeHausmateCookie() {
  Cookies.remove("hausmate", { domain: MAIN_DOMAIN })
}

function* loginSaga({ payload }: PayloadAction<LoginDetails>) {
  try {
    const { user } = yield call(rsf.auth.signInWithEmailAndPassword, payload.email, payload.password)
    yield handleAuthSuccess(user)
    addHausmateCookie()
  } catch (error) {
    const code = error.code
    let message = error.message
    if (code == LOGIN_ERROR_INVALID_EMAIL) {
      message = "Please enter a valid email."
    } else if (code == LOGIN_ERROR_WRONG_PASSWORD) {
      message = "Invalid password. Please try again"
    } else if (code == LOGIN_ERROR_USER_NOT_FOUND) {
      message = NO_ACCOUNT_MESSAGE
    } else if (!message) {
      message = GENERIC_ERROR
    }

    yield put(loginFailure({ code, message }))
  }
}

function* logoutSaga() {
  try {
    removeHausmateCookie()
    yield call(rsf.auth.signOut)
    clearUserProfile()
    yield put(logoutSuccess())
  } catch (error) {
    yield put(logoutFailure(error))
  } finally {
    // always clear user profile while logging out
    clearUserProfile()
    yield put(reset())
  }
}


function* handleAuthSuccess(user: firebase.User) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `users/${user.uid}`)
    const userInfo = snapshot.data()
    if (userInfo != null) {
      const profile = profileTransformer(userInfo, user)
      setUserProfile(profile)
      yield (put(loginSuccess(profile)))
    } else {
      yield put(loginFailure({ code: LOGIN_ERROR_USER_NOT_FOUND, message: NO_ACCOUNT_MESSAGE }))
    }
  } catch (error) {
    yield put(loginFailure(error))
  }
}

export default function* loginRootSaga() {
  yield all([
    takeLatest(login, loginSaga),
    takeEvery(logout, logoutSaga)
  ])
}