import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CreateGroupSuccessData, WorkoutModeState } from "./types"
import { WorkoutVideo } from "../types"
import { ErrorInfo } from "../../../redux/types"
import { fullLink, isIOS, isSafari } from "../../../utils"
import { userInteractedToPlayVideo } from "../../solo/soloSlice"


const initialState: WorkoutModeState = {
  loading: false,
  inviteFriends: false
}

export const workoutModeSlice = createSlice({
  name: "workoutMode",
  initialState: initialState,
  reducers: {
    createGroupWorkout: (state, action: PayloadAction<WorkoutVideo>) => {
      state.loading = true
    },
    createGroupSuccess: (state, { payload }: PayloadAction<CreateGroupSuccessData>) => {
      state.loading = false
      state.newGroupId = payload.groupId
      state.inviteFriends = payload.inviteFriends
      state.inviteText = payload.inviteText
      if (payload.inviteFriends) {
        state.inviteLink = fullLink(payload.groupUrl)
      } else {
        state.newGroupUrl = payload.groupUrl
      }
    },
    createGroupFailure: (state, { payload }: PayloadAction<ErrorInfo>) => {
      state = initialState
      state.error = payload
    },
    soloWorkoutSelected: state => {
      if (!(isIOS || isSafari)) {
        userInteractedToPlayVideo()
      }
    },
    reset: state => initialState,
    clearErrors: state => {
      state.error = null
    }
  }
})

export const {
  createGroupWorkout,
  createGroupSuccess,
  createGroupFailure,
  soloWorkoutSelected,
  reset,
  clearErrors
} = workoutModeSlice.actions

export default workoutModeSlice.reducer