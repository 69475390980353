import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ErrorInfo } from "../../redux/types"
import { ReviewInit, ReviewState } from "./type"
import { getUserProfile } from "../../utils"

const initialState: ReviewState = {
  loading: false,
  submitted: false,
  rating: 0,
  isGroup: false
}

export const reviewSlice = createSlice({
  name: "review",
  initialState: initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<ReviewInit>) => {
      state.workoutId = payload.workoutId
      state.isGroup = payload.isGroup
      state.reviewerName = getUserProfile()?.displayName ?? ""
    },
    updateStars: (state, { payload }: PayloadAction<number>) => {
      state.rating = payload
    },
    submitFeedback: (state, { payload }: PayloadAction<string>) => {
      state.feedbackMessage = payload
    },

    submitFeedbackSuccess: (state) => {
      state.loading = false
      state.submitted = true
    },
    submitFeedbackError: (state, { payload }: PayloadAction<ErrorInfo>) => {
      state.error = payload
      state.submitted = false
    },
    reset: state => initialState
  }
})

export const {
  init,
  updateStars,
  submitFeedback,
  submitFeedbackSuccess,
  submitFeedbackError,
  reset
} = reviewSlice.actions

export default reviewSlice.reducer