export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  width: 640,
  height: 360,
  frameRate: 18
}

export const DEFAULT_MOBILE_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  width: 640,
  frameRate: 15
}

export const ENABLE_BANDWIDTH_WARNING = false