import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AccountState, AccountSummaryData, ChangeEmailDetails, LogClassData, ResetPasswordDetails } from "./types"
import { ErrorInfo } from "../../redux/types"
import { WorkoutVideo } from "../browse/types"

const initialState: AccountState = {
  fetchingWorkout: false,
  loading: false,
  resetPasswordData: null,
  changeEmailData: { loading: false }
}

export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    fetchAccountSummary: (state) => {
      state.loading = true
    },

    accountSummarySuccess: (state, { payload }: PayloadAction<AccountSummaryData>) => {
      state.loading = false
      state.error = null
      state.accountSummaryData = payload
    },

    accountSummaryFailure: (state, { payload }: PayloadAction<ErrorInfo>) => {
      state.loading = false
      state.error = payload
    },

    fetchWorkout: (state, { payload }: PayloadAction<string>) => {
      state.fetchingWorkout = true
    },

    fetchWorkoutSuccess: (state, { payload }: PayloadAction<WorkoutVideo>) => {
      state.fetchingWorkout = false
      state.selectedWorkout = payload
    },

    fetchWorkoutFailure: (state, { payload }: PayloadAction<ErrorInfo>) => {
      state.loading = false
      state.error = payload
    },
    clearSelectedWorkout: (state) => {
      state.selectedWorkout = null
      state.fetchingWorkout = false
    },

    logClassHistory: (state, action: PayloadAction<LogClassData>) => state,
    uploadProfilePic: (state, action: PayloadAction<File>) => {
      state.loading = true
    },
    uploadProfilePicSuccess: (state, { payload }: PayloadAction<string>) => {
      state.loading = false
      if (state.accountSummaryData) {
        state.accountSummaryData.profileImageUrl = payload
      }
    },
    uploadProfilePicFailure: (state, action: PayloadAction<ErrorInfo>) => {
      state.loading = false
      state.error = action.payload
    },
    reset: (state) => initialState,

    sendResetPasswordEmail: (state, actions: PayloadAction<ResetPasswordDetails>) => {
      state.resetPasswordData = { loading: true }
    },

    resetPasswordEmailSent: (state, { payload }: PayloadAction<string>) => {
      state.resetPasswordData = { loading: false, success: true, email: payload }
    },

    resetPasswordEmailFailure: (state, { payload }: PayloadAction<ErrorInfo>) => {
      state.resetPasswordData = { loading: false, error: payload, success: false }
    },

    changeEmail: (state, action: PayloadAction<ChangeEmailDetails>) => {
      state.changeEmailData.loading = true
    },

    changeEmailSuccess: (state, { payload }: PayloadAction<string>) => {
      state.changeEmailData.loading = false
      state.changeEmailData.success = true
      state.changeEmailData.email = payload
      state.accountSummaryData!.email = payload
    },

    changeEmailFailure: (state, { payload }: PayloadAction<ErrorInfo>) => {
      state.changeEmailData.loading = false
      state.changeEmailData.error = payload
      state.changeEmailData.success = false
    },

    clearResetPassword: (state) => {
      state.resetPasswordData = null
    },
    clearChangeEmail: state => {
      state.changeEmailData = { loading: false }
    }

  }
})

export const {
  fetchAccountSummary,
  accountSummarySuccess,
  accountSummaryFailure,
  fetchWorkout,
  logClassHistory,
  fetchWorkoutSuccess,
  fetchWorkoutFailure,
  clearSelectedWorkout,
  uploadProfilePic,
  uploadProfilePicSuccess,
  uploadProfilePicFailure,
  sendResetPasswordEmail,
  resetPasswordEmailSent,
  resetPasswordEmailFailure,
  clearResetPassword,
  changeEmail,
  changeEmailSuccess,
  changeEmailFailure,
  clearChangeEmail,
  reset
} = accountSlice.actions

export default accountSlice.reducer