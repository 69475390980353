import { all, call, put, takeLatest } from "redux-saga/effects"
import rsf from "../../redux/rsf"
import { showDashboard, showDashboardFailure, showInactiveMembership } from "./membershipSlice"
import { getErrorInfo } from "../../redux/types"
import { fullLink, isBrowser } from "../../utils"
import { WORKOUTS_HOME } from "../routing/Locations"
import { checkIfPaidMember } from "../browse/ActivateMembership/activateMembershipSaga"


function* showStripeDashboardSaga() {
  try {
    const isMember = yield checkIfPaidMember()

    if (isMember) {
      if (isBrowser) {
        const portalLinkFunction = rsf.app.functions(rsf.region).httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink")
        const { data } = yield call(portalLinkFunction, { returnUrl: `${fullLink(WORKOUTS_HOME)}` })
        window.location.assign(data.url)
      }
    } else {
      yield put(showInactiveMembership())
    }
  } catch (e) {
    yield put(showDashboardFailure(getErrorInfo(e)))
  }

}

export default function* membershipRootSaga() {
  yield all([
    takeLatest(showDashboard, showStripeDashboardSaga)
  ])
}