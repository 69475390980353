import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LiveState, ShoutoutDisplay } from "./types"

const initialState: LiveState = {
  numHausmates: 0,
  isLive: false,
  scheduledClass: false,
  playedSeconds: -1
}

export const liveSlice = createSlice({
  name: "live",
  initialState: initialState,
  reducers: {
    initLive: (state, { payload }: PayloadAction<string>) => {
      state.liveId = payload
      state.isLive = true
    },
    updateHausmatesCount: (state, { payload }: PayloadAction<number>) => {
      state.numHausmates = payload
    },
    updateScheduledStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.scheduledClass = payload
    },
    shoutoutClicked: (state) => state,

    showShoutout: (state, { payload }: PayloadAction<ShoutoutDisplay>) => {
      state.shoutout = payload
    },
    updatePlayedSeconds: (state, { payload }: PayloadAction<number>) => {
      state.playedSeconds = payload
    },
    clearLive: (state) => initialState
  }
})

export const {
  initLive,
  updateHausmatesCount,
  updateScheduledStatus,
  updatePlayedSeconds,
  shoutoutClicked,
  showShoutout,
  clearLive
} = liveSlice.actions

export default liveSlice.reducer