import { all, call, put, takeLatest, throttle, debounce } from "redux-saga/effects"
import {
  accountSummaryFailure,
  accountSummarySuccess,
  changeEmail,
  changeEmailFailure,
  changeEmailSuccess,
  fetchAccountSummary,
  fetchWorkout,
  fetchWorkoutFailure,
  fetchWorkoutSuccess,
  logClassHistory,
  resetPasswordEmailFailure,
  resetPasswordEmailSent,
  sendResetPasswordEmail,
  uploadProfilePic,
  uploadProfilePicFailure,
  uploadProfilePicSuccess
} from "./accountSlice"
import { getErrorInfo } from "../../redux/types"
import { getUserProfile, isValidString, setUserProfile } from "../../utils"
import {
  AccountSummaryData,
  ChangeEmailDetails,
  HistoryItem,
  LogClassData,
  ResetPasswordDetails,
  StoreHistoryItem
} from "./types"
import { fetchWorkoutHelper, RESET_PLAYBACK_THRESHOLD } from "../solo/soloSaga"
import { PayloadAction } from "@reduxjs/toolkit"
import { Difficulty, WorkoutVideo } from "../browse/types"
import rsf from "../../redux/rsf"
import firebase from "firebase"
import moment from "moment"
import { getResetPasswordContinueUrl } from "../routing/Locations"

const NUM_LOG_CALLS_BEFORE_LOGGING_HISTORY = 4
const LOG_HISTORY_THROTTLE_MS = 30000
const LOG_HISTORY_PROGRESS_DEBOUNCE_MS = 5000
const LOG_HISTORY_PROGRESS_THROTTLE_MS = 60000



function* fetchAccountSummarySaga() {
  try {
    const profile = getUserProfile()
    const historyItems: HistoryItem[] = yield call(fetchClassHistorySaga, profile!.uid)
    const profileImageUrl: string | null = yield call(getProfileImageDownloadUrl, profile!.uid)
    const accountSummaryData: AccountSummaryData = {
      email: profile?.email ?? "",
      initial: profile?.displayName.substring(0, 1) ?? "",
      name: profile?.displayName ?? "",
      numClasses: historyItems.length,
      numClassesThisWeek: getNumberOfClassesThisWeek(historyItems),
      profileImageUrl,
      historyItems
    }
    yield put(accountSummarySuccess(accountSummaryData))
  } catch (e) {
    yield put(accountSummaryFailure(getErrorInfo(e)))
  }
}

function* getProfileImageDownloadUrl(uid: string) {
  try {
    return yield call(rsf.storage.getDownloadURL, `profiles/${uid}`)
  } catch (e) {

  }
  return null
}

function* uploadProfilePicSaga({ payload }: PayloadAction<File>) {
  try {
    if (isFileImage(payload)) {
      const profile = getUserProfile()
      if (profile) {
        yield rsf.storage.uploadFile(`profiles/${profile.uid}`, payload)
        const profileImageUrl: string | null = yield call(getProfileImageDownloadUrl, profile!.uid)
        if (profileImageUrl) {
          yield put(uploadProfilePicSuccess(profileImageUrl))
        }
      }
    } else {
      yield put(uploadProfilePicFailure({ message: "Please select a jpeg or png file", errorTime: Date.now() }))
    }
  } catch (e) {
    yield put(uploadProfilePicFailure(getErrorInfo(e)))
  }
}

function isFileImage(file: File) {
  const acceptedImageTypes = ["image/jpg", "image/jpeg", "image/png"]
  return file && acceptedImageTypes.includes(file["type"])
}

function* fetchClassHistorySaga(uid: string) {
  const historyRef = getHistoryCollectionRef(uid)
  const snapshot: firebase.firestore.QuerySnapshot = yield call(rsf.firestore.getCollection, historyRef)
  return snapshot.docs
    .filter((doc) => doc.exists)
    .map((doc): HistoryItem => {
      return {
        classTimestamp: doc.data().classTimestamp.toMillis(),
        difficulty: doc.data().difficulty as Difficulty,
        id: doc.id,
        subtitle: doc.data().subtitle,
        thumbnailUrl: doc.data().thumbnailUrl,
        title: doc.data().title,
        wid: doc.data().wid,
        scheduledClass: doc.data().scheduledClass ?? false,
        groupClass: doc.data().groupClass ?? false,
        timeSlotId: doc.data().timeSlotId
      }
    })
    .sort((a, b) => (b.classTimestamp - a.classTimestamp))
}

function* fetchWorkoutSaga({ payload }: PayloadAction<string>) {
  try {
    const workout: WorkoutVideo | null = yield call(fetchWorkoutHelper, payload)
    if (workout)
      yield put(fetchWorkoutSuccess(workout))
    else
      yield put(fetchWorkoutFailure({ message: "Unable to find workout.", errorTime: Date.now() }))
  } catch (error) {
    yield put(fetchWorkoutFailure(getErrorInfo(error)))
  }
}

let logCalls = 0
let logWid: string | null = null

function* logClassHistorySaga({ payload }: PayloadAction<LogClassData>) {
  try {
    if (logWid !== payload.wid) {
      logCalls = 0
      logWid = payload.wid
    }
    const profile = getUserProfile()

    if (profile && logCalls++ == NUM_LOG_CALLS_BEFORE_LOGGING_HISTORY) {
      const workout: WorkoutVideo | null = yield call(fetchWorkoutHelper, payload.wid)
      if (workout) {
        const canCreateHistoryItem: boolean = yield call(checkClassHistoryDoesNotExistSaga, profile.uid, payload.wid)
        if (canCreateHistoryItem) {
          yield call(createHistoryItemSaga, profile.uid, workout, payload)
        }
      }
    }
  } catch (e) {
    console.error("logClassHistorySaga", e)
  }
}

function* logClassHistoryProgressSaga({ payload }: PayloadAction<LogClassData>) {
    try{
      const profile = getUserProfile()
      if(profile) {
        const historyDocId = yield call(getRelevantClassHistoryIdSaga, profile.uid, payload.wid)
        if(isValidString(historyDocId)) {
          yield call(rsf.firestore.updateDocument, getHistoryDocumentRef(profile.uid, historyDocId), {
            progress: payload.playbackInfo.playedRatio,
            updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
          })
        }
      }
    }catch (e) {
      console.error("logClassHistoryProgressSaga", e)
    }
}

function* checkClassHistoryDoesNotExistSaga(userId: string, wid: string) {
  const historyDocId: string | null = yield call(getRelevantClassHistoryIdSaga, userId, wid)
  return historyDocId === null
}

function *getRelevantClassHistoryIdSaga(userId: string, wid: string) {
  const historyRef = getHistoryCollectionRef(userId)
  const thresholdTimestamp = firebase.firestore.Timestamp.fromMillis(Date.now() - RESET_PLAYBACK_THRESHOLD)
  // @ts-ignore
  const snapshot: firebase.firestore.QuerySnapshot = yield call(rsf.firestore.getCollection, historyRef.where("updateTimestamp", ">=", thresholdTimestamp))
  if(snapshot.empty)
    return null

  const docs = snapshot.docs.filter(doc => doc.exists && doc.data().wid == wid)
  return docs.length == 0 ? null : docs[0].id

}

function* createHistoryItemSaga(userId: string, workout: WorkoutVideo, data: LogClassData) {
  const historyRef = getHistoryCollectionRef(userId)

  const item: StoreHistoryItem = {
    classTimestamp: firebase.firestore.Timestamp.fromMillis(data.classTimestamp),
    difficulty: workout.mainDifficulty as Difficulty,
    subtitle: workout.subtitle,
    thumbnailUrl: workout.thumbnailUrl,
    title: workout.title,
    scheduledClass: data.scheduledClass,
    groupClass: data.groupClass,
    timeSlotId: data.timeSlotId ?? null,
    updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    wid: workout.id,
    progress: data.playbackInfo.playedRatio
  }

  const doc: firebase. firestore.DocumentReference  = yield call(rsf.firestore.addDocument, historyRef, item)
  return doc.id
}

function* sendResetPasswordEmailSaga({ payload }: PayloadAction<ResetPasswordDetails>) {
  try {
    const email = payload.email ?? (getUserProfile()?.email ?? "")
    yield call(rsf.auth.sendPasswordResetEmail, email, { url: getResetPasswordContinueUrl() })
    yield put(resetPasswordEmailSent(email))
  } catch (e) {
    yield put(resetPasswordEmailFailure(getErrorInfo(e)))
  }
}

function* changeEmailSaga({ payload }: PayloadAction<ChangeEmailDetails>) {
  try {
    const profile = getUserProfile()
    if (profile) {
      yield call(rsf.auth.signInWithEmailAndPassword, profile.email, payload.password!)
      const newEmail = payload.email!
      yield call(rsf.auth.updateEmail, newEmail)
      yield call(rsf.firestore.updateDocument, `users/${profile.uid}`, { email: newEmail }),
        yield put(changeEmailSuccess(newEmail))
      profile.email = newEmail
      setUserProfile(profile)
    } else {
      yield put(changeEmailFailure({ message: "Profile does not exist", errorTime: Date.now() }))
    }
  } catch (e) {
    yield put(changeEmailFailure(getErrorInfo(e)))
  }
}

function getNumberOfClassesThisWeek(historyItems: HistoryItem[]): number {
  const startAndEndOfWeek = getStartAndEndOfWeekDates()
  return historyItems.filter((h) => h.classTimestamp >= startAndEndOfWeek[0] && h.classTimestamp <= startAndEndOfWeek[1]).length
}

function getHistoryCollectionRef(uid: string): firebase.firestore.CollectionReference {
  return rsf.app.firestore().collection(`users/${uid}/history`)
}

function getHistoryDocumentRef(uid: string, docId: string): firebase.firestore.DocumentReference {
  return rsf.app.firestore().doc(`users/${uid}/history/${docId}`)
}


function getStartAndEndOfWeekDates(): number[] {
  const startOfWeek = moment().startOf("isoWeek").toDate().getTime()
  const endOfWeek = moment().endOf("isoWeek").toDate().getTime()
  return [startOfWeek, endOfWeek]
}

export default function* accountRootSaga() {
  yield all([
    takeLatest(fetchAccountSummary, fetchAccountSummarySaga),
    takeLatest(fetchWorkout, fetchWorkoutSaga),
    takeLatest(uploadProfilePic, uploadProfilePicSaga),
    takeLatest(sendResetPasswordEmail, sendResetPasswordEmailSaga),
    takeLatest(changeEmail, changeEmailSaga),
    throttle(LOG_HISTORY_THROTTLE_MS, logClassHistory, logClassHistorySaga),
    throttle(LOG_HISTORY_PROGRESS_THROTTLE_MS, logClassHistory, logClassHistoryProgressSaga),
    debounce(LOG_HISTORY_PROGRESS_DEBOUNCE_MS, logClassHistory, logClassHistoryProgressSaga)
  ])
}