import { all, call, cancelled, put, race, select, take, takeLatest, throttle } from "redux-saga/effects"
import rsf from "../../redux/rsf"
import {
  applyingSortAndFilter,
  applyTransientFilters,
  clean,
  clearFilters,
  filterValueChanged,
  filterWorkoutsSuccess,
  init,
  previewWorkoutById,
  scheduleClassesUpdated,
  sortWorkouts,
  updateSelectedWorkout,
  workoutSelected
} from "./browseSlice"
import {
  ALL_ID,
  BrowseState, Difficulty,
  Duration,
  FetchInputData,
  FilterType, getDifficultyIndex,
  getDurationMs,
  SCHEDULE_CLASS_PREJOIN_BUFFER,
  ScheduleDateFormats,
  SortType,
  WorkoutSelectedTypes,
  WorkoutVideo
} from "./types"
import { delay } from "../../utils"
import { RootState } from "../../redux/store"
import firebase from "firebase"
import { PayloadAction } from "@reduxjs/toolkit"
import { countdown } from "../../redux/saga"
import moment from "moment"

const browseState = (state: RootState) => state.browse


function* scheduleTickerSaga() {
  const chan = yield call(countdown, 31536000, 1)
  try {
    while (true) {
      yield take(chan)
      let browseData: BrowseState = yield select(browseState) // <-- get the project
      const scheduleClasses: WorkoutVideo[] = []
      let updatedSelectedClass: WorkoutVideo | null = null
      browseData.scheduledClasses.forEach((c, index, classes) => {

        const scheduledClass: WorkoutVideo = Object.assign({}, c)
        scheduledClass.scheduleInfo = Object.assign({}, scheduledClass.scheduleInfo)

        const classDuration = getDurationMs(c.durationCategory as Duration)
        const currentTime = Date.now()
        const joinStartTime = (scheduledClass.scheduleInfo!.startTimeInMs - SCHEDULE_CLASS_PREJOIN_BUFFER)
        const endTime = (scheduledClass.scheduleInfo!.startTimeInMs + classDuration)


        if (currentTime < endTime) {
          scheduledClass.scheduleInfo!.isLive = currentTime > scheduledClass.scheduleInfo!.startTimeInMs && currentTime < endTime
          scheduledClass.scheduleInfo!.ctaState = scheduledClass.scheduleInfo!.isLive || (currentTime > joinStartTime) ? "join" : "invite"

          if (scheduledClass.scheduleInfo?.ctaState === "join") {
            scheduledClass.scheduleInfo!.countdown = moment.utc(Math.abs(scheduledClass.scheduleInfo!.startTimeInMs - currentTime)).format(ScheduleDateFormats.countdownLongMinSec).toString()
          }


          scheduleClasses.push(scheduledClass)
          if (browseData.selectedWorkout?.scheduleInfo) {
            if (scheduledClass.scheduleInfo!.id === browseData.selectedWorkout!.scheduleInfo!.id) {
              updatedSelectedClass = scheduledClass
            }
          }
        }

      })

      if (updatedSelectedClass) {
        yield put(updateSelectedWorkout(updatedSelectedClass))
      }

      yield put(scheduleClassesUpdated(scheduleClasses))
    }
  } finally {
    if (yield cancelled()) {
      chan.close()
      console.log("countdown cancelled")
    }
  }
}

function* filterAndSortWorkoutsSaga() {
  try {
    yield put(applyingSortAndFilter())
    let browseData: BrowseState = yield select(browseState) // <-- get the project

    const filteredWorkouts = browseData.allWorkouts
      .filter(w => filterWorkoutByCategories(w, browseData.activeFilters[FilterType.category.toString()]))
      .filter(w => filterWorkoutByDifficulty(w, browseData.activeFilters[FilterType.difficulty.toString()]))
      .filter(w => filterWorkoutByDuration(w, browseData.activeFilters[FilterType.duration.toString()]))
      .filter(w => filterWorkoutByEquipment(w, browseData.activeFilters[FilterType.equipment.toString()]))
      .filter(w => filterWorkoutByInstructor(w, browseData.activeFilters[FilterType.instructor.toString()]))
      .sort(sortCompareFunction(browseData.activeSort))

    const categoryTitle = getWorkoutsFilterTitle(browseData.activeFilters[FilterType.category.toString()])
    const filterBreadcrumbs = getFilterBreadcrumb(browseData.activeFilters)
    // add slight delay for the ui to change
    yield delay(20)
    yield put(filterWorkoutsSuccess({ workouts: filteredWorkouts, title: categoryTitle, filterBreadcrumbs }))


  } catch (e) {
    console.log("filter error: " + e.toString())
  }
}

function* storeActiveFiltersSaga() {
  try {
    let browseData: BrowseState = yield select(browseState) // <-- get the project
    const filterData = {
      active: browseData.activeFilters,
      creationTime: firebase.firestore.FieldValue.serverTimestamp()
    }

    const filtersPath = `users/${rsf.app.auth().currentUser?.uid}/prefs/filters`

    yield call(rsf.firestore.setDocument, filtersPath, filterData, {})
    yield call(rsf.firestore.addDocument, `${filtersPath}/history`, filterData)

  } catch (e) {
    console.log(`storeActiveFiltersSaga error : ${e.toString()}`)
  }
}

function* fetchWorkoutSaga({ payload }: PayloadAction<FetchInputData>) {
  let browseData: BrowseState = yield select(browseState)
  let workout: WorkoutVideo | undefined
  let type: WorkoutSelectedTypes = "on-demand"
  if (payload.tsId) {
    workout = browseData.scheduledClasses.find((s) => s.scheduleInfo!.id === payload.tsId)
    type = "schedule-detail"
  } else {
    workout = browseData.allWorkouts.find((w) => w.id == payload.wid)
  }

  if (workout) {
    yield put(workoutSelected({ workout, type }))
  }
}

function sortCompareFunction(sortType: SortType): (a: WorkoutVideo, b: WorkoutVideo) => number {
  if (sortType == SortType.new) {
    return (a, b) => (a.creationTime > b.creationTime) ? -1 : 1
  } else if (sortType == SortType.old) {
    return (a, b) => (a.creationTime > b.creationTime) ? 1 : -1
  } else if (sortType == SortType.popular) {
    return (a, b) => (a.significantViews > b.significantViews) ? -1 : 1
  } else if (sortType == SortType.category) {
    return (a, b) => {
      const aHasCategories = Array.isArray(a.categories) && a.categories.length
      const bHasCategories = Array.isArray(b.categories) && b.categories.length
      if (!aHasCategories && !bHasCategories)
        return 0
      if (!aHasCategories)
        return 1
      if (!bHasCategories)
        return -1

      const primaryCatCompare = a.categories[0].localeCompare(b.categories[0])
      if (primaryCatCompare == 0) {
        if (a.categories.length > 1 && b.categories.length > 1) {
          return a.categories[1].localeCompare(b.categories[1])
        }
        if (a.categories.length > 1)
          return 1
        else if (b.categories.length > 1)
          return -1
        return sortCompareFunction(SortType.new)(a, b)
      }
      return primaryCatCompare
    }
  } else if(sortType == SortType.difficulty) {
    return (a, b) => {
      const aDifficulty = getDifficultyIndex(a.mainDifficulty as Difficulty);
      const bDifficulty = getDifficultyIndex(b.mainDifficulty as Difficulty);

      if(aDifficulty < bDifficulty)
        return -1;
      else if (aDifficulty > bDifficulty)
        return 1;
      return sortCompareFunction(SortType.new)(a, b)
    }
  }

  throw new Error("Unknown Sort Type")
}

function filterWorkoutByCategories(workout: WorkoutVideo, categoryFilters: any): boolean {
  return Object.keys(categoryFilters).length == 0 ||
    categoryFilters[ALL_ID] === true ||
    workout.categories.some((c) => categoryFilters[c] == true)
}

function filterWorkoutByDifficulty(workout: WorkoutVideo, difficultyFilters: any): boolean {
  return Object.keys(difficultyFilters).length == 0 ||
    difficultyFilters[workout.mainDifficulty] === true

  //un comment this to make use of multiple difficulties prop
  //workout.difficulties.some((c) => difficultyFilters[c] == true)
}

function filterWorkoutByDuration(workout: WorkoutVideo, durationFilters: any): boolean {
  return Object.keys(durationFilters).length == 0 ||
    durationFilters[workout.durationCategory] === true
}

function filterWorkoutByEquipment(workout: WorkoutVideo, equipmentFilters: any): boolean {
  return Object.keys(equipmentFilters).length == 0 ||
    workout.equipments.some((c) => equipmentFilters[c] == true)
}

function filterWorkoutByInstructor(workout: WorkoutVideo, instructorFilters: any): boolean {
  return Object.keys(instructorFilters).length == 0 ||
    workout.instructors.some((c) => instructorFilters[c] == true)
}

function getWorkoutsFilterTitle(activeCategoryFilters: any) {
  const activeCategoryDisplayNames: any = Object.keys(activeCategoryFilters)
    .filter(k => k.includes("_display"))
    .map(k => activeCategoryFilters[k])
  let categories = ""
  if (activeCategoryDisplayNames.length == 0) {
    categories = "On-Demand"
  } else
    categories = concatWithCommaAndAmp(activeCategoryDisplayNames) ?? ""
  return `${categories} classes`
}

function getFilterBreadcrumb(activeFilters: any): string | null {

  const result: (string | null)[] = []
  result.push(
    concatWithCommaAndAmp(
      getActiveFilterDisplayName(activeFilters[FilterType.difficulty.toString()])
    ))


  result.push(getDurationBreadcrumb(activeFilters[FilterType.duration.toString()]))

  result.push(
    concatWithCommaAndAmp(
      getActiveFilterDisplayName(activeFilters[FilterType.instructor.toString()])
    ))

  result.push(getEquipmentBreadcrumb(activeFilters[FilterType.equipment.toString()]))


  if (result && result.length > 0)
    return result.filter((s) => s != null).join(" / ")

  return null
}

function getDurationBreadcrumb(durationFilters: any): string | null {
  const durations = getActiveFilterDisplayName(durationFilters)
    .map((k: string) => k.replace(" minutes", ""))
    .map((d: string) => parseInt(d))

  durations.sort((n1: number, n2: number) => n1 - n2)
  const breadcrumb = concatWithCommaAndAmp(durations)
  if (breadcrumb != null) {
    return `${breadcrumb} minutes`
  }
  return null
}

function getEquipmentBreadcrumb(equipmentFilters: any): string | null {
  if (equipmentFilters && Object.keys(equipmentFilters).length == 2) {
    if (equipmentFilters["yes"]) {
      return "Equipment"
    }
    return "No Equipment"
  }
  return null
}

function getActiveFilterDisplayName(activeFiltersForType: any): any {
  return Object.keys(activeFiltersForType)
    .filter(k => k.includes("_display"))
    .map(k => activeFiltersForType[k])
}

function concatWithCommaAndAmp(labels: string[]): string | null {
  if (Array.isArray(labels) && labels.length) {
    if (labels.length == 1) {
      return labels[0]
    } else {
      const last = labels.pop()
      return labels.join(", ") + " & " + last
    }
  }
  return null
}


export function workoutVideoTransformer(videoData: any, uid: string): WorkoutVideo {
  return {
    scheduleTitle: videoData.scheduleTitle,
    categories: videoData.categories,
    creationTime: videoData.creationTime.toMillis(),
    difficulties: videoData.difficulties,
    mainDifficulty: videoData.mainDifficulty,
    thumbnailUrl: videoData.thumbnailUrl,
    description: videoData.description,
    durationCategory: videoData.durationCategory,
    instructors: videoData.instructors,
    subtitle: videoData.subtitle,
    title: videoData.title,
    rating: videoData.rating,
    gifUrl: videoData.gifUrl,
    equipments: videoData.equipments,
    pageTitle: `${videoData.title.toUpperCase()} | ${videoData.subtitle.toUpperCase()}`,
    significantViews: videoData.significantViews,
    id: uid
  }
}

export function sortWorkoutsByDifficulty(workouts: WorkoutVideo[]) {
  if (workouts) {
    workouts.sort(sortCompareFunction(SortType.difficulty))
  }
}

export function sortWorkoutsByTime(workouts: WorkoutVideo[]) {
  if (workouts) {
    workouts.sort(sortCompareFunction(SortType.new))
  }
}

export function sortWorkoutsBySchedule(workouts: WorkoutVideo[]) {
  if (workouts) {
    workouts.sort((a, b) => (a.scheduleInfo!.startTimeInMs < b.scheduleInfo!.startTimeInMs) ? -1 : 1)
  }
}


export default function* browseRootSaga() {
  yield all([
    takeLatest(filterValueChanged, filterAndSortWorkoutsSaga),
    takeLatest(sortWorkouts, filterAndSortWorkoutsSaga),
    takeLatest(applyTransientFilters, filterAndSortWorkoutsSaga),
    takeLatest(clearFilters, filterAndSortWorkoutsSaga),
    takeLatest(previewWorkoutById, fetchWorkoutSaga),
    takeLatest(init, function* () {
      yield race({
        task: call(scheduleTickerSaga),
        cancel: take(clean)
      })
    }),
    throttle(5000, filterValueChanged, storeActiveFiltersSaga)
  ])
}
