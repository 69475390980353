import { ErrorInfo } from "../../redux/types"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MembershipState } from "./types"

const initialState: MembershipState = {
  loading: true,
  inactiveMember: false
}

export const membershipSlice = createSlice({
  name: "membership",
  initialState: initialState,
  reducers: {
    showDashboard: state => {
      state.loading = true
    },
    showInactiveMembership: state => {
      state.loading = false
      state.inactiveMember = true
    },
    showDashboardFailure: (state, action: PayloadAction<ErrorInfo>) => {
      state.loading = false
      state.error = action.payload
      state.inactiveMember = false
    },
    clean: state => initialState
  }
})

export const {
  showDashboard,
  showInactiveMembership,
  showDashboardFailure,
  clean
} = membershipSlice.actions

export default membershipSlice.reducer