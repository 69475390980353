import isPlainObject from "is-plain-object"
import firebase from "firebase"
import firestore from "firebase"
import "firebase/firestore"
import { Profile } from "../redux/types"

export const isBrowser = typeof window !== `undefined`

export const isMobile = (() => {
  if (typeof navigator === "undefined" || typeof navigator.userAgent !== "string") {
    return false
  }
  return /Mobile/.test(navigator.userAgent)
})()

export const isSafari = (() => {
  if (typeof navigator === "undefined" || typeof navigator.userAgent !== "string") {
    return false
  }
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
})()

export const isIOS = (() => {
  if(!isBrowser)
    return false

  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
})()

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(devices => devices.every(device => !(device.deviceId && device.label)))
    .then(shouldAskForMediaPermissions => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(mediaStream => mediaStream.getTracks().forEach(track => track.stop()))
      }
    })
}

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj

  const target: { [name: string]: any } = {}

  for (const key in obj) {
    const val = obj[key]
    if (typeof val !== "undefined") {
      target[key] = removeUndefineds(val)
    }
  }

  return target as T
}

export function profileTransformer(userInfo: any, firebaseUser: firebase.User): Profile {
  return {
    displayName: userInfo.displayName as string,
    uid: firebaseUser.uid,
    email: firebaseUser.email as string,
    onboardingComplete: userInfo.onboardingComplete ?? false
  }
}

export function instanceOfFirestoreError(object: any): object is firestore.FirebaseError {
  return "code" in object
}


let profile: Profile | null

export function hasUserProfileKey(): boolean {
  return isBrowser && (profile != null || window.localStorage.profile)
}

export function getUserProfile() {
  try {
    if (profile) {
      return profile
    }
    profile = window.localStorage.profile
      ? JSON.parse(window.localStorage.profile) as Profile
      : null
    return profile
  } catch (e) {
    return null
  }
}

export const setUserProfile = (user: Profile) => {
  profile = user
  window.localStorage.profile = JSON.stringify(user)
}
export const clearUserProfile = () => {
  profile = null
  window.localStorage.removeItem("profile")
}

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const fullLink = (link: string) => {
  return `${window ? window.origin : ""}${link}`
}

export function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function validateEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email)
}

export function isValidString(value: string | undefined | null) {
  return  typeof value !== 'undefined' && value
}
