import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RouteState } from "./type"

const initialState: RouteState = {
  redirectSuccess: null
}

export const routeSlice = createSlice({
  name: "route",
  initialState: initialState,
  reducers: {
    updateRedirectSuccess: (state, { payload }: PayloadAction<string>) => {
      state.redirectSuccess = payload
    },
    reset: state => initialState
  }
})

export const {
  updateRedirectSuccess,
  reset
} = routeSlice.actions

export default routeSlice.reducer