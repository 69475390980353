import firebase from "firebase"
import { all, call, cancelled, put, race, select, take, takeLatest, throttle } from "redux-saga/effects"
import rsf from "../../../redux/rsf"
import { PayloadAction } from "@reduxjs/toolkit"
import {
  alertUser,
  countdownUpdate,
  groupInfoUpdate,
  groupInfoUpdateFailure,
  init,
  joinGroupSuccess,
  playVideo,
  reset,
  showFeedback,
  startWorkout,
  userInteracted,
  userInteractedSuccess,
  videoPlaybackUpdate
} from "./groupSlice"
import { GroupInfo, GroupWorkoutInit, GroupWorkoutState } from "./types"
import { GENERIC_ERROR, GroupMemberType, PlaybackInfo } from "../../../redux/types"
import { RootState } from "../../../redux/store"
import { Difficulty, SCHEDULE_CLASS_PREJOIN_BUFFER, ScheduleDateFormats } from "../../browse/types"
import { getGroupUrl } from "../../routing/Locations"
import { getUserProfile } from "../../../utils"
import { generateParticipantIdentity } from "../utils"
import { clockOffsetSaga, countdown } from "../../../redux/saga"
import { clearLive, initLive, updatePlayedSeconds } from "../../live/liveSlice"
import moment from "moment"
import { getInviteMessage } from "../../browse/ChooseWorkoutMode/shareMessageGenerator"
import { logClassHistory } from "../../account/accountSlice"


const ONDEMAND_COUNTDOWN_DURATION = 10000

const groupWorkoutState = (state: RootState) => state.groupWorkouts

// @ts-ignore
function* groupInitSaga({ payload }: PayloadAction<GroupWorkoutInit>) {
  try {
    const groupDocPath = `groupworkouts/${payload.groupId}`

    const [snapshot, offset, joinGroupInfo] = yield all([
      call(rsf.firestore.getDocument, groupDocPath),
      call(clockOffsetSaga, true),
      call(joinGroupSaga, payload.groupId)
    ])

    yield put(joinGroupSuccess(joinGroupInfo))

    const groupInfo = snapshot.data()
    if (groupInfo != null) {
      yield onGroupDocUpdate(groupInfo, true, offset, payload.groupId)
      yield initLiveSaga()
    } else {
      yield put(groupInfoUpdateFailure({ message: "Group doesn't exist" }))
    }

    yield listenToGroupDocUpdates(groupDocPath, offset, payload.groupId)

  } catch (error) {
    // Handle Errors here.
    const code = error.code
    let message = error.message
    if (!message) {
      message = GENERIC_ERROR
    }
    yield put(groupInfoUpdateFailure({ code, message }))
  }
}

function* listenToGroupDocUpdates(groupDocPath: string, offset: number, groupId: string) {
  // @ts-ignore
  const channel = rsf.firestore.channel(groupDocPath, "document")
  while (true) {
    const doc = yield take(channel)
    yield onGroupDocUpdate(doc.data(), false, offset, groupId)
  }
}

function* onGroupDocUpdate(groupDocData: any, init: boolean = false, offset: number = 0, groupId: string) {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState) // <-- get the project

  const members = groupDocData.members ?? {}
  let startTime: number = -1
  if (groupDocData.scheduleClassTimestamp) {
    startTime = groupDocData.scheduleClassTimestamp.toMillis() + offset
  } else if (groupDocData.hostStartTimestamp) {
    startTime = groupDocData.hostStartTimestamp.toMillis() + ONDEMAND_COUNTDOWN_DURATION + offset
  }

  let groupInfo: GroupInfo = {
    startTime,
    numMembers: members.length,
    videoId: groupDocData.videoId ?? "",
    videoThumbnailUrl: groupDocData.videoThumbnailUrl ?? "",
    videoGifUrl: groupDocData.videoGifUrl,
    videoSubtitle: groupDocData.videoSubtitle ?? "",
    videoTitle: groupDocData.videoTitle ?? "",
    videoRating: groupDocData.videoRating ?? -1,
    videoDifficulty: groupDocData.videoDifficulty ?? Difficulty.beginner,
    videoCategories: groupDocData.videoCategories ?? [],
    alreadyEnded: groupDocData.ended ?? false,
    hostName: groupDocData.hostName,
    isHost: members[firebase.auth().currentUser?.uid ?? ""] === GroupMemberType.host.toString(),
    shareLink: `${window ? window.origin : ""}${getGroupUrl(groupDocData.videoId, groupId)}`,
    videoDurationCategory: groupDocData.videoDurationCategory,
    videoDescription: groupDocData.videoDescription,
    videoEquipments: groupDocData.videoEquipments,
    shareLinkCopy: "",
    videoPageTitle: "",
    scheduleClassTimestamp: !groupDocData.scheduleClassTimestamp ? null : groupDocData.scheduleClassTimestamp.toMillis(),
    scheduleId: groupDocData.scheduleId,
    scheduled: groupDocData.scheduled ?? false
  }
  groupInfo.videoPageTitle = `${groupInfo.videoTitle.toUpperCase()} | ${groupInfo.videoSubtitle.toUpperCase()}`
  if (groupInfo.isHost) {
    groupInfo.shareLinkCopy = getInviteMessage(groupInfo.hostName, groupInfo.videoTitle, groupInfo.shareLink, groupInfo.scheduleClassTimestamp, groupInfo.videoEquipments)
  }

  yield put(groupInfoUpdate(groupInfo))

  // if user has interacted and start time has updated, start countdown
  if (groupState.userInteractionComplete && groupState.groupInfo!.startTime !== groupInfo.startTime) {
    yield call(countdownSaga)
  }
}

function* hostStartWorkoutSaga() {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState)
  if (groupState.numChatParticipants > 1) {
    yield put(userInteractedSuccess())
    const groupDocPath = `groupworkouts/${groupState.groupId}`
    yield call(rsf.firestore.updateDocument, groupDocPath, { hostStartTimestamp: firebase.firestore.FieldValue.serverTimestamp() })
  } else {
    yield put(alertUser({
      title: "Share class link",
      message: "Please share the class link with your friends and hit start when everyone's ready."
    }))
  }
}

function* userInteractedSaga() {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState)
  if (Date.now() > (groupState.startTime - SCHEDULE_CLASS_PREJOIN_BUFFER)) {
    yield put(userInteractedSuccess())
    yield initLiveSaga()
  } else {
    yield put(alertUser({ title: "You're Early!", message: "You can join a class 10 minutes before it starts." }))
  }
}

function* joinGroupSaga(groupId: string) {
  const identity = generateParticipantIdentity(getUserProfile())
  const joinGroup = rsf.app.functions(rsf.region).httpsCallable("joinGroup")
  const joinGroupInfo = yield call(joinGroup, { identity, groupId })
  return joinGroupInfo.data
}

function* videoPlaybackUpdateSaga({ payload }: PayloadAction<PlaybackInfo>) {
  try {
    let groupState = yield select(groupWorkoutState)

    if (groupState.groupInfo.isHost) {
      const groupDocPath = `groupworkouts/${groupState.groupId}`
      yield call(rsf.firestore.updateDocument, groupDocPath, {
        playedSeconds: payload.playedSeconds,
        playedRatio: payload.playedRatio,
        ended: payload.playedRatio >= 0.99
      })
    }
  } catch (error) {
    // no need to handle errors as this is a background call
  }
}

function* videoPlaybackFeedbackSaga({ payload }: PayloadAction<PlaybackInfo>) {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState)

  if (groupState.classStarted)
    yield put(updatePlayedSeconds(payload.playedSeconds))

  if (groupState.classStarted)
    yield put(logClassHistory({
      wid: groupState.groupInfo!.videoId,
      classTimestamp: groupState.startTime,
      scheduledClass: groupState.groupInfo?.scheduled ?? false,
      groupClass: true,
      timeSlotId: groupState.groupInfo?.scheduleId,
      playbackInfo: payload
    }))


  if (payload.playedRatio >= 0.999)
    yield put(showFeedback(!groupState.showWaitingRoom))
}

function* countdownWorkerSaga(startTime: number) {
  const countdownTime = Math.round((startTime - Date.now()) / 1000)
  if (countdownTime > 0) {
    const chan = yield call(countdown, countdownTime, 1)
    try {
      while (true) {
        let seconds = yield take(chan)
        const countdownMs = seconds * 1000
        yield put(countdownUpdate({
          tickerText: moment.utc(countdownMs).format(seconds > 10 ? ScheduleDateFormats.countdownLongMinSec : ScheduleDateFormats.countdownSec).toString(),
          large: countdownMs <= ONDEMAND_COUNTDOWN_DURATION
        }))
      }
    } finally {
      if (yield cancelled()) {
        chan.close()
        console.log("countdown cancelled")
      } else {
        yield put(playVideo())
      }
    }
  } else {
    yield put(playVideo())
  }
}

function* countdownSaga() {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState)
  if (groupState.groupInfo && groupState.userInteractionComplete) {
    let startTime = groupState.groupInfo.startTime
    if (startTime < 0 && groupState.showStartUI) {
      startTime = Date.now() + ONDEMAND_COUNTDOWN_DURATION
    }
    if (startTime > 0) {
      yield race({
        task: call(countdownWorkerSaga, startTime),
        cancel: take(reset)
      })
    }
  }
}

function* initLiveSaga() {
  let groupState: GroupWorkoutState = yield select(groupWorkoutState)
  if (groupState.groupInfo?.isHost && !groupState.groupInfo?.scheduled || groupState.userInteractionComplete) {
    yield put(initLive(groupState.groupInfo!.scheduled ? groupState.groupInfo!.scheduleId! : groupState.groupId!))
    return true
  } else {
    return false
  }
}


export default function* groupRootSaga() {
  yield all([
    takeLatest(init, groupInitSaga),
    takeLatest(userInteractedSuccess, countdownSaga),
    takeLatest(startWorkout, hostStartWorkoutSaga),
    takeLatest(userInteracted, userInteractedSaga),
    throttle(5000, videoPlaybackUpdate, videoPlaybackUpdateSaga),
    takeLatest(videoPlaybackUpdate, videoPlaybackFeedbackSaga),
    takeLatest(reset, function* () {
      yield put(clearLive())
    })
  ])
}