import { all, call, fork } from "redux-saga/effects"
import loginSaga from "../features/login/loginSaga"
import registerSaga from "../features/register/registerSaga"
import browseSaga from "../features/browse/browseSaga"
import groupSaga from "../features/videochat/redux/groupSaga"
import soloSaga from "../features/solo/soloSaga"
import workoutModeSaga from "../features/browse/ChooseWorkoutMode/workoutModeSaga"
import reviewSaga from "../features/review/reviewSaga"
import { END, eventChannel } from "redux-saga"
import liveSaga from "../features/live/liveSaga"
import { delay, getUserProfile } from "../utils"
import rsf from "./rsf"
import firebase from "firebase"
import accountSaga from "../features/account/accountSaga"
import analyticsSaga from "../features/analytics/analyticsSaga"
import activateMembershipSaga from "../features/browse/ActivateMembership/activateMembershipSaga"
import membershipSaga from "../features/membership/membershipSaga"


export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(registerSaga),
    fork(browseSaga),
    fork(groupSaga),
    fork(soloSaga),
    fork(workoutModeSaga),
    fork(reviewSaga),
    fork(liveSaga),
    fork(accountSaga),
    fork(analyticsSaga),
    fork(membershipSaga),
    fork(activateMembershipSaga)
  ])
}


export function countdown(secs: number, interval: number = 1) {
  return eventChannel(emitter => {
      const iv = setInterval(() => {
        secs -= interval
        if (secs > 0) {
          emitter(secs)
        } else {
          // this causes the channel to close
          emitter(END)
        }
      }, interval * 1000)
      // The subscriber must return an unsubscribe function
      return () => {
        clearInterval(iv)
      }
    }
  )
}


let offset: number | null = null

export function* clockOffsetSaga(refresh: boolean) {

  if (!offset || refresh) {
    let profile = getUserProfile()
    if (profile) {
      const userPath = `users/${profile.uid}`

      yield call(delay, refresh ? 0 : 1000)

      const timeStart = Date.now()
      yield call(rsf.firestore.updateDocument, userPath, { tsSync: firebase.firestore.FieldValue.serverTimestamp() })
      const timeTaken = Date.now() - timeStart
      const doc = yield call(rsf.firestore.getDocument, userPath)
      const serverTime = doc.data().tsSync.toMillis()

      let cliOffset = 0
      if (Math.abs(serverTime - timeStart) > timeTaken) {
        cliOffset = timeStart - serverTime - Math.round(timeTaken / 2)
      }

      offset = cliOffset
    } else {
      offset = 0
    }
  }
  return offset
}