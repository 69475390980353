import firebase from "firebase"
import { all, call, put, takeLatest } from "redux-saga/effects"
import rsf from "../../redux/rsf"
import { REGISTER_ERROR_EMAIL_USED, RegisterDetails } from "./types"
import { PayloadAction } from "@reduxjs/toolkit"
import { register, registerFailure, registerSuccess } from "./registerSlice"
import { profileTransformer, setUserProfile } from "../../utils"
import { GENERIC_ERROR } from "../../redux/types"


function* registerSaga({ payload }: PayloadAction<RegisterDetails>) {
  try {
    const { user } = yield call(rsf.auth.createUserWithEmailAndPassword, payload.email, payload.password)
    yield onCreateUserSuccess(user, payload)
  } catch (error) {
    // Handle Errors here.
    const code = error.code
    let message = error.message
    if (code == "auth/weak-password") {
      message = "The password is too weak."
    } else if (code == REGISTER_ERROR_EMAIL_USED) {
      message = "This email already has an account associated with it. Please login to continue."
    } else if (!message) {
      message = GENERIC_ERROR
    }

    yield put(registerFailure({ code, message }))
  }
}


function* onCreateUserSuccess(user: firebase.User, registerDetails: RegisterDetails) {
  try {

    yield call(updateDisplayNameSaga, registerDetails.displayName)

    const userInfo = { displayName: registerDetails.displayName, email: user.email, creationTimestamp:  firebase.firestore.FieldValue.serverTimestamp()}
    yield call(rsf.firestore.setDocument, `users/${user.uid}` as string, userInfo, {})

    let profile = profileTransformer(userInfo, user)
    setUserProfile(profile)
    yield (put(registerSuccess(profile)))
  } catch (error) {
    yield put(registerFailure(error))
  }
}

function* updateDisplayNameSaga(displayName: string) {
  try {
    yield call(rsf.auth.updateProfile, { displayName });
  }
  catch(error) {
    console.error("Unable to update profile display name", error)
  }
}


export default function* registerRootSaga() {
  yield all([
    takeLatest(register, registerSaga)
  ])
}