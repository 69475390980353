import { fullLink } from "../../utils"

export const LOGIN = `/login`
export const REGISTER = "/register"
export const WORKOUTS_HOME = "/"
export const CLASS_DETAIL = "/c"
export const SOLO_VIDEO = "/s"
export const PROFILE = "/profile"
export const MEMBERSHIP = "/membership"
export const FAQ = "/faq"
export const LANDING_PAGE = "https://haus.live"
export const MAIN_DOMAIN = "haus.live"


export function getGroupUrl(wid: string, gid: string): string {
  return `/g/${wid}?gid=${gid}`
}

export function getResetPasswordContinueUrl() {
  return fullLink(LOGIN)
}

export function getSoloUrl(wid: string, tsid?: string | null): string {
  return `${SOLO_VIDEO}/${wid}${tsid ? "?tsId=" + tsid : ""}`
}

export function getClassDetailUrl(wid: string, tsid?: string | null): string {
  return `${CLASS_DETAIL}/${wid}${tsid ? "?tsId=" + tsid : ""}`
}
